import React from 'react';
import './App.css';

const FontsList = ({ selectedFont, onFontChange }) => {
  const fonts = [
      { value: 'ACBlur', label: 'ACBlur', source: '/Fonts/ACBlur.ttf' },
      { value: 'CWDRKAGE', label: 'CWDRKAGE', source: '/Fonts/CWDRKAGE.ttf' },
      { value: 'Lycanthrope', label: 'Lycanthrope', source: '/Fonts/Lycanthrope.ttf' },
      { value: 'SATYP___', label: 'SATYP', source: '/Fonts/SATYP___.ttf' },
      { value: 'Shay Man Regular', label: 'Shay Man Regular', source: '/Fonts/Shay Man Regular.ttf' },
      // Standard Fonts
      { value: 'Arial', label: 'Arial' },
      { value: 'Arial Black', label: 'Arial Black' },
      { value: 'Comic Sans MS', label: 'Comic Sans MS' },
      { value: 'Courier New', label: 'Courier New' },
      { value: 'Georgia', label: 'Georgia' },
      { value: 'Impact', label: 'Impact' },
      { value: 'Lucida Console', label: 'Lucida Console' },
      { value: 'Lucida Sans Unicode', label: 'Lucida Sans Unicode' },
      { value: 'Palatino Linotype', label: 'Palatino Linotype' },
      { value: 'Segoe UI', label: 'Segoe UI' },
      { value: 'Tahoma', label: 'Tahoma' },
      { value: 'Times New Roman', label: 'Times New Roman' },
      { value: 'Trebuchet MS', label: 'Trebuchet MS' },
      { value: 'Verdana', label: 'Verdana' },
    ];



    return (

        <select value={selectedFont} onChange={onFontChange} style={{ fontFamily: selectedFont }} className="select-dropdown">
          {fonts.map((font) => (
              <option key={font.value} value={font.value} style={{ fontFamily: font.value }}>
              {font.label}
        </option>
      ))}
    </select>
  );
};

export default FontsList;