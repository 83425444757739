import React from 'react';
import AdjustableImage from './AdjustableImage.jsx';
import { getBackgroundStyle } from 'advanced-cropper'; // Make sure this import is correct

function AdjustableCropperBackground(
    { className, cropper, crossOrigin, brightness = 0, saturation = 0, hue = 0, contrast = 0, blur = 0 },
    ref
) {
    const state = cropper.getState();
    const transitions = cropper.getTransitions();
    const image = cropper.getImage();

    const style = image && state ? getBackgroundStyle(image, state, transitions) : {};

    return (
        <AdjustableImage
            src={image && image.src}
            crossOrigin={crossOrigin}
            brightness={brightness}
            saturation={saturation}
            hue={hue}
            contrast={contrast}
            blur={blur}
            ref={ref}
            className={className}
            style={style}
        />
    );
}

export default React.forwardRef(AdjustableCropperBackground);
