import React, {  useRef } from "react";
import cn from "classnames";
import  CropIcon  from "../icons/CropIcon";
import  HueIcon  from "../icons/HueIcon";
import  SaturationIcon  from "../icons/SaturationIcon";
import  ContrastIcon  from "../icons/ContrastIcon";
import  BrightnessIcon  from "../icons/BrightnessIcon";
import  UploadIcon  from "../icons/UploadIcon";
import  DownloadIcon  from "../icons/DownloadIcon";
import BlurIcon from "../icons/BlurIcon";
import Button from "./Button";
import "./Navigation.css";

const Navigation = ({
    className,
    onChange,
    onUpload,
    onDownload,
    mode
}) => {
    const setMode = (mode) => () => {
        onChange?.(mode);
    };

    const inputRef = useRef(null);

    const onUploadButtonClick = () => {
        inputRef.current?.click();
    };

    const onLoadImage = (event) => {
        // Reference to the DOM input element
        const { files } = event.target;

        // Ensure that you have a file before attempting to read it
        if (files && files[0]) {
            if (onUpload) {
                onUpload(URL.createObjectURL(files[0]));
            }
        }
        // Clear the event target value to give the possibility to upload the same image:
        event.target.value = "";
    };

    return (
        <div className={cn("image-editor-navigation", className)}>
            <div className="image-editor-navigation__buttons">
                <Button
                    className={"image-editor-navigation__button"}
                    onClick={onUploadButtonClick}
                >
                    <span className="tooltip">Upload Image</span>
                    <UploadIcon />
                    <input
                        ref={inputRef}
                        type="file"
                        accept="image/*"
                        onChange={onLoadImage}
                        className="image-editor-navigation__upload-input"
                    />
                </Button>
                </div>
                <div className="image-editor-navigation__buttons">
                <Button
                    className={"image-editor-navigation__button"}
                    active={mode === "crop"}
                    onClick={setMode("crop")}
                >
                    <span className="tooltip">Crop</span>
                    <CropIcon />
                </Button>
                <Button
                    className={"image-editor-navigation__button"}
                    active={mode === "saturation"}
                    onClick={setMode("saturation")}
                >
                    <span className="tooltip">Saturation</span>
                    <SaturationIcon />
                </Button>
                <Button
                    className={"image-editor-navigation__button"}
                    active={mode === "brightness"}
                    onClick={setMode("brightness")}
                >
                    <span className="tooltip">Brightness</span>
                    <BrightnessIcon />
                </Button>
                <Button
                    className={"image-editor-navigation__button"}
                    active={mode === "contrast"}
                    onClick={setMode("contrast")}
                >
                    <span className="tooltip">Contrast</span>
                    <ContrastIcon />
                </Button>
                <Button
                    className={"image-editor-navigation__button"}
                    active={mode === "hue"}
                    onClick={setMode("hue")}
                >
                    <span className="tooltip">Hue</span>
                    <HueIcon />
                </Button>
                <Button
                    className={"image-editor-navigation__button"}
                    active={mode === "blur"}
                    onClick={setMode("blur")}
                >
                    <span className="tooltip">Blur</span>
                    <BlurIcon />
                </Button>
            </div>
            <div className="image-editor-navigation__buttons">
                <Button
                    className={"image-editor-navigation__button"}
                    onClick={onDownload}
                >
                    <span className="tooltip">Save Image</span>
                    <DownloadIcon />
                </Button>
                </div>
        </div>
    );
};

export default Navigation;
