// HomeSection1.jsx
import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Template1 from '../../assets/images/Template1.webp';
import Template2 from '../../assets/images/Template2.webp';
import Template3 from '../../assets/images/Template3.webp';
import InAppScreen from '../../assets/images/InAppScreen.webp';

const HowItWorks = () => {
    return (
        <section className="home-section">
            <header>
                <h1 className="home-section-title">
                    How It Works
                </h1>
                <p className="home-section-description">
                    Pick a template, upload your audio tracks and images, and generate your
                    videos in just a few clicks.
                </p>
                <p className="home-section-description">
                    You can now animate your album art into video and bring your music to life automatically!
                </p>
                {/* Add Image */}
                <div>
                    <Link to={`/post/1`} className="link">
                        <img
                            src={InAppScreen}
                            alt="Free online video maker for album art animation"
                            className="home-section-image-BIG"
                            loading="lazy" /* Enable lazy loading for performance */
                        />
                    </Link>
                </div>


            </header>




            <section>
                <p className="home-section-tagline">
                    Take your music to the next level with visually stunning videos.
                </p>
            </section>

            <br></br>
            <div>
                <br></br>
                <Link className="link" to={`/post/1`}>
                    <button className="sectionButton">Start Editing Now!</button>
                </Link>
            </div>

        </section>
    );
};

export default HowItWorks;
