// HomeSection1.jsx
import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Template1 from '../../assets/images/Template1.webp';
import Template2 from '../../assets/images/Template2.webp';
import Template3 from '../../assets/images/Template3.webp';

const WhoIsFor = () => {
    return (
        <section className="home-section">

            <header>
                <h1 className="home-section-title">
                    Who Is Album Animator For?
                </h1>
                <p className="home-section-description">
                    Whether you’re an artist, producer, or music enthusiast, Album Animator automates the video creation process, saving you time and effort.
                </p>
                <p className="home-section-description">
                    Promote your music, share your creativity, and captivate your audience with stunning visual content.
                </p>

                <p className="home-section-tagline">
                    Now you can focus on what really matters — your music!
                </p>
            </header>


            <br></br>

            <div>
                <br></br>

                <Link className="link" to={`/post/1`}>
                    <button className="sectionButton">Join for Free!</button>
                </Link>
            </div>

        </section>
    );
};

export default WhoIsFor;
