import React, { useEffect } from 'react';
import '../App.css';





const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="home">

            <div className="simplePage-container">

                <div className="privacy-policy">
                    <h2>Privacy Policy</h2>
                    <br />
                    <div className="desc">
                        <p>We value your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website and tell you about your privacy rights and how the law protects you.</p>
                    </div>
                    <br />

                    <h3>What Data We Collect</h3>
                    <br />
                    <div className="desc">
                        <p>We may collect the following types of personal data:</p>
                        <ul>
                            <li>Identity Data (e.g., name, username)</li>
                            <li>Contact Data (e.g., email address)</li>
                            <li>Technical Data (e.g., IP address, browser type)</li>
                            <li>Usage Data (e.g., information about how you use our website)</li>
                        </ul>
                    </div>
                    <br />

                    <h3>How We Use Your Data</h3>
                    <br />
                    <div className="desc">

                        <p>We will use your personal data for the following purposes:</p>
                        <ul>
                            <li>To provide and manage your account</li>
                            <li>To manage our relationship with you</li>
                            <li>To improve our website and services</li>
                            <li>To comply with legal obligations</li>
                        </ul>
                    </div>
                    <br />

                    <h3>How We Share Your Data</h3>
                    <br />

                    <div className="desc">
                        <p>We may share your personal data with the following parties:</p>
                        <ul>
                            <li>Service providers who provide IT and system administration services</li>
                            <li>Professional advisers</li>
                            <li>Regulators and other authorities</li>
                        </ul>
                    </div>

                    <br />
                    <h3>Your Rights</h3>
                    <br />
                    <div className="desc">
                        <p>You have the following rights regarding your personal data:</p>
                        <ul>
                            <li>The right to access your personal data</li>
                            <li>The right to correct your personal data</li>
                            <li>The right to delete your personal data</li>
                            <li>The right to object to the processing of your personal data</li>
                        </ul>
                    </div>
                    <br />

                    <h2>Cookies Policy</h2>
                    <br />
                    <div className="desc">

                        <p>We use cookies to enhance your browsing experience and provide you with a more personalized service. This cookies policy explains what cookies are, how we use them, and how you can manage them.</p>
                    </div>
                    <br />

                    <h3>What Are Cookies?</h3>
                    <br />
                    <div className="desc">

                        <p>Cookies are small text files that are placed on your computer or mobile device when you visit a website. They are widely used to make websites work more efficiently, as well as to provide information to the owners of the site.</p>
                    </div>
                    <br />

                    <h3>Types of Cookies We Use</h3>
                    <br />
                    <div className="desc">

                        <ul>
                            <li>Necessary Cookies: These are essential for the website to function properly.</li>
                            <li>Performance Cookies: These help us understand how visitors interact with our website.</li>
                            <li>Functionality Cookies: These enable enhanced functionality and personalization.</li>
                            <li>Advertising Cookies: These are used to deliver ads that are relevant to you.</li>
                        </ul>
                    </div>
                    <br />

                    <h3>Managing Cookies</h3>
                    <br />
                    <div className="desc">

                        <p>You can manage or disable cookies through your browser settings. However, please note that disabling cookies may affect the functionality of our website.</p>
                    </div>
                    <br />

                    <h3>Third-Party Cookies</h3>
                    <br />
                    <div className="desc">

                        <p>We may also use third-party cookies from services such as Google Analytics to analyze website traffic and usage.</p>
                    </div>
                </div>


            </div>



        </div>
    )

}

export default PrivacyPolicy;