import Google from "../img/google.png";
//import Facebook from "../img/facebook.png";
//import Github from "../img/github.png";
//import { Link } from "react-router-dom";


const Login = () => {

    const google = () => {
       // window.open("http://localhost:5000/auth/google", "_self");
        window.open("https://dolphin-app-56orb.ondigitalocean.app/auth/google", "_self");
    //    window.open("https://dolphin-app-56orb.ondigitalocean.app:8080/auth/google", "_self");
    };

    return (

        <div className="login">


            <div className="loginContainer">
                <h2>Choose a Login Method</h2>
                <br />
                <br />
                <center>
                    <div className="loginButton google" onClick={google} >
                        <img src={Google} alt="" className="icon" />
                        Google
                    </div>
                    <br />
                    <div className="desc">
                        <p><i>More Login Methods coming soon..</i></p>
                        <p><i>Stay Tuned!</i></p>
                    </div>
                </center>
            </div>
        </div>


    )

}

export default Login;

















//return (
//    <div className="login">
//        <h1 className="loginTitle">Choose a Login Method</h1>
//        <div className="wrapper">
//            <div className="left">
//                <div className="loginButton google" onClick={google}>
//                    <img src={Google} alt="" className="icon" />
//                    Google
//                </div>
//                <div className="loginButton facebook">
//                    <img src={Facebook} alt="" className="icon" />
//                    Facebook
//                </div>
//                <div className="loginButton github">
//                    <img src={Github} alt="" className="icon" />
//                    Github
//                </div>
//            </div>
//            <div className="center">
//                <div className="line" />
//                <div className="or">OR</div>
//            </div>
//            <div className="right">
//                <input type="text" placeholder="Username" className="inputLogin" />
//                <input type="text" placeholder="Password" className="inputLogin" />
//                <button className="submit">Login</button>
//            </div>
//        </div>
//    </div>
//)