import React from 'react';
import '../css/buttons.css';
import '../App.css';

const EditColorsButton = ({ onClick, label, isActive }) => {
    return (
        <button className={`svg-button ${isActive ? 'svg-button-active' : ''}`} onClick={onClick}>

            <svg
                className="svg-InColumn" 
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 256 256"
                style={{ enableBackground: 'new 0 0 256 256' }}
                xmlSpace="preserve"
            >
                <metadata>Svg Vector Icons : http://www.onlinewebfonts.com/icon</metadata>
                <g>
                    <g>
                        <path
                            d="M137.8,185.5c0,11,8.9,20,20,20c11,0,20-8.9,20-20s-8.9-20-20-20C146.8,165.6,137.8,174.5,137.8,185.5z M44.5,117.1c0,8.5,6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4s-6.9-15.4-15.4-15.4S44.5,108.6,44.5,117.1z M243.2,96.8c0,0-5.4-10.2-17.6-24.2c-3.3,3.5-8.8,9.6-15.7,16.9c3.6,4.3,5.4,6.9,5.4,6.9c1.5,4.5,6.8,13,2.6,20.5c-4.1,7.5-10.5,9.6-20.2,14.6c-19.3,10.1-14.9,30-4.9,57.6c3.2,8.7,8,23.8-3.5,28.1c-7.1,2.7-15,4.1-25,4c-10.4-0.1-23-1.9-36.8-5.7C82.9,203,52,179,39.8,145.2c-12.2-33.9-9.6-77.2,15.4-97.5c24.9-20.2,69.5-23,105.3-0.4c4.7,3,9.2,6,13.3,9c8-7.1,14.3-12.8,17.4-15.5c-4.8-3.5-9.9-7.1-15.5-10.5c-45.9-28.4-103-25.5-135,0c-32,25.6-38.1,79.2-22.5,121.9c15.7,42.7,54.2,77.1,112.5,88.7c14.8,3,28.7,4.6,41.2,5c14.7,0.4,27.5-1.2,37.6-5c14.8-5.5,15.3-22.2,11.2-33.3c-12.8-34.8-24.7-53.7,0-66.5c12.4-6.4,17.2-8.5,22.5-17.9S245.1,102.5,243.2,96.8z M117.6,119.9c-4.7,5.2-23.5,25-29,47.3c21.6-2.7,34.4-12.7,49.8-25c8.6-11.7,53.8-56.7,53.8-56.7l43.9-45.3c0,0-0.3-5.1-3-7.9c-2.3-2.4-7.1-2.6-7.1-2.6L174.9,73C174.9,73,129.8,110.4,117.6,119.9z M132.3,54.4c0-7.8-6.3-14.1-14.1-14.1c-7.8,0-14.1,6.3-14.1,14.1c0,7.8,6.3,14.1,14.1,14.1C126,68.5,132.3,62.2,132.3,54.4z M91.2,74.1c0-7.8-6.3-14.1-14.1-14.1s-14.1,6.3-14.1,14.1c0,7.8,6.3,14.1,14.1,14.1S91.2,81.9,91.2,74.1z"
                        />
                    </g>
                </g>
            </svg>
            {/*    {label}*/}
        </button>
    );
}

export default EditColorsButton;
