import React, { useState } from 'react';
import "../App.css";


const ColorPicker = ({ id, Initcolor, Initopacity, name, onColorChange }) => {
    const [color, setColor] = useState(Initcolor); // Initial color
    const [opacity, setOpacity] = useState(Initopacity); // Initial opacity value


    const handleChangeColor = (event) => {
        const newColor = event.target.value;
        setColor(newColor);
        onColorChange(id, newColor, opacity, name); // Call the callback function with the ID, new color, and current opacity
        console.log(name);
    };

    const handleChangeOpacity = (event) => {
        const newOpacity = event.target.value;
        setOpacity(newOpacity);
        onColorChange(id, color, newOpacity,name); // Call the callback function with the ID, current color, and new opacity
    };

    return (
        <div>
            {name === 'Glow' || name === 'Playing' ? (
                // This div is specifically for when name is "Glow"
                <div>
                    <input type="color" value={color} className="color-picker" onChange={handleChangeColor} />
                    <div style={{ marginTop: '10px' }}> <b>{name}:</b> <span className="simpleText">{color}</span></div>
                    <div>
                        <input
                            type="range"
                            id={`opacity-${id}-glow`}
                            min="0"
                            max="30"
                            step="1"
                            value={opacity}
                            onChange={handleChangeOpacity}
                            style={{ width: '90%' }}
                            className="opacity-slider"
                        />
                        <div>Blur: <span className="simpleText">{opacity}</span></div>
                    </div>
                </div>
            ) : (
                // This div is for all other names (name1, name3, etc.)
                <div>
                        <input type="color" value={color} className="color-picker" onChange={handleChangeColor} />
                        <div style={{ marginTop: '10px' }}> <b>{name}:</b> <span className="simpleText">{color}</span></div>
                    <div>
                        {/* You may want to adjust the label for opacity if it's not used for "Glow" */}
                            <input
                                type="range"
                                id={`opacity-${id}`}
                                min="0"
                                max="1"
                                step="0.1"
                                value={opacity}
                                onChange={handleChangeOpacity}
                                style={{ width: '90%' }}
                                className="opacity-slider"
                            />
                            <div>Opacity: <span className="simpleText">{opacity}</span></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ColorPicker;
