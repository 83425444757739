import { posts } from "../templatesData";
import  Card  from "../components/Card";
import '../App.css';
import { Link } from "react-router-dom";
import HeroSection from '../components/HomeSections/HeroSection'; 
import HowItWorks from '../components/HomeSections/HowItWorks'; 
import Features from '../components/HomeSections/Features'; 
import WhoIsFor from '../components/HomeSections/WhoIsFor'; 
import PatreonSection from '../components/HomeSections/PatreonSection'; 

const Home = () => {
    return (
        <main>   

            <div className="home-page">
                <HeroSection />
                <br></br>
                <br></br>
                <div className="blueLine"></div>
                <br></br>
                <HowItWorks />
                <br></br>
                <br></br>
                <div className="blueLine"></div>
                <br></br>
                <Features />
                <br></br>
                <br></br>
                <div className="blueLine"></div>
                <br></br>
                <WhoIsFor />
                <br></br>
                <br></br>
                <div className="blueLine"></div>
                <br></br>
                <PatreonSection />
                <br></br>
                <br></br>
                <div className="blueLine"></div>
                <br></br>

                {/* Add more sections here */}
            </div>



        </main>
    )

}

export default Home;