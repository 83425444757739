import React from 'react';

const DiscordButton = () => {
    const inviteLink = 'https://discord.gg/yW4Kbxpw3X'; // Replace with your actual Discord invite link

    return (
        <button
            onClick={() => window.open(inviteLink, '_blank')}
            style={{
                backgroundColor: '#7289DA',
                color: '#FFF',
                border: 'none',
                padding: '5px 10px',
                fontSize: '16px',
                borderRadius: '5px',
                cursor: 'pointer'
            }}
        >
            Join Our Discord
        </button>
    );
};

export default DiscordButton;
