import DiscordButton from '../components/DiscordButton';



const Contact = () => {




    return (

        <div className="login">


            <div className="loginContainer">
                <h2>Contact Me!</h2>
               
                <center>
                    <br />
                    <div className="desc">
                        <p>Found a bug? Wanna share some ideas? Please feel free to send your email at</p>
                        <p className="simpleText"><b>jyro@AlbumAnimator.io</b></p>
                        <p>or join our discord channel pressing the button below!</p>

                    </div>
                    <br />

                    <DiscordButton />

                   
                </center>
            </div>
        </div>


    )

}

export default Contact;





