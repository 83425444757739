import React from 'react';
import '../../App.css';
import PatreonButton from '../PatreonButton';

const PatreonSection = () => {
    return (
        <section className="home-section">
            <h2 className="home-section-title">Support Album Animator on Patreon</h2>
            <p className="home-section-description">
                Album Animator is free to use for its basic version, but we need your support to bring the next version of the app to life.
            </p>
            <p className="home-section-description">
                By becoming a Patreon supporter, you’ll help us create exciting new features, enhance performance, and keep Album Animator accessible to everyone.
            </p>
            <p className="home-section-tagline">
                Join us on this journey and be part of the innovation! Your support makes a difference.
            </p>


            <div>
                <br></br>
                <PatreonButton />

            </div>
        </section>
    );
};

export default PatreonSection;
