import React, { useEffect } from 'react';
import '../App.css';
import PatreonButton from '../components/PatreonButton';





const TheProject = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="home">

            <div className="simplePage-container">

                <div className="privacy-policy">
                    <h2>About this Project</h2>
                    <br />
                    <div className="desc">
                        <p style={{paddingBottom:'10px'}}>'Album Animator' is a passion project of 'Jyro'. It's an ongoing work that started at 2023.</p>
                        <p style={{ paddingBottom: '5px' }}>The whole idea started when some friends and I were running an underground music label. 
                                So I wanted to convert our music albums into videos, in order to upload them on Youtube and other plartforms.</p>

                        <p style={{ paddingBottom: '10px' }}>The problem was that for a simple video, I had to use a video editor, and create a video for each audio track independently. 
                            What I needed was bulk rendering all the audio tracks of an album into videos, with just a few clicks. 
                                And after much digging, I couldn't find a web app doing this.</p>
                            So as you can imagine.. I decided I should be the one to do it! At least give it a try.
                        <p style={{ paddingBottom: '10px' }}>And after more than a year of dedication, hard work and much fun,
                            I can proudly present you the first and free version of my web app 
                            <i> 'Album Animator' v1.0 !</i></p>

                    </div>


                    <br />


                    <h3>How it works?</h3>
                    <br />
                    <div className="desc">
                        <p style={{ paddingBottom: '10px' }}>
                            Album Animator, is a web app that helps you batch render the audio tracks of 
                            your music album or audio playlist all at once, 
                            into a list of videos, one for each track, and a finalized video for your whole album!
                        </p>

                        <p style={{ paddingBottom: '10px' }}>
                            The app performs the rendering process on your browser, 
                            so that I can keep the basic version of this web app absolutely free!
                        </p>

                                          

                        <ul>

                            <li>
                        <p style={{ paddingBottom: '10px' }}>
                        Upload a set of audio tracks, and the algorithm detects and separates 'Track Number', 'Artist' and 'Song'.
                        The best way to achieve this, is by naming your files using the following format:
                                </p>
                            
                            <p style={{ paddingBottom: '10px' }}>
                                [Track Number] - [Artist] - [Song]. For example: '03 - Kalacakra - Deja vu'.
                            </p>
                            </li>

                            <li>
                        <p style={{ paddingBottom: '10px' }}>
                            Then, upload your album's Cover Image and a Background image, and apply various effects on them.
                                </p>
                            </li>

                            <li>
                        <p style={{ paddingBottom: '10px' }}>
                            If you feel creative, you can choose theme colors, hide or show elements and apply effects on the final video.
                                </p>
                            </li>

                            <li>
                        <p style={{ paddingBottom: '10px' }}>
                            And finally, hit 'Export' and watch your whole Music Album automatically transform into videos,
                            each one with its own 'Artist' and 'Song', 
                            ready to upload anywhere!
                                </p>
                            </li>

                            <li>
                        <p style={{ paddingBottom: '10px' }}>
                            If you don't want to upload each video separately,
                            you can use your finalized 'Album Video' where it plays all your tracks in one single video!
                            Copy the generated text and paste it in the youtube video description, 
                            and you album video will have tracks separators on the play bar. 
                                </p>
                            </li>

                      
                        </ul>


                    </div>
                    <br />

                    <h3>Next Steps</h3>
                    <br />
                    <div className="desc">

                        <p style={{ paddingBottom: '10px' }}>
                            With your support, I'm planning to take the following steps:
                        </p>
                        <ul>
                            <li>Develop v2.0, where the processing can run on a server.</li>
                            <li>Develop real time Visualizer and moving Particles following the beat. </li>
                            <li>Add Video Gallery section, to save your albums and videos.</li>
                            <li>Add Automated Youtube Upload button.</li>
                            <li>Create more Templates.</li>
                            <li>Clean up the code.</li>
                            <li>Collaborate with Javascript artists to create more stunning visualizations.</li>
                        </ul>
                    </div>

                    <p style={{ paddingBottom: '10px' }}>
                        You can help this project grow bigger and better, by joining our Patreon:
                    </p>

                    <br />
                        <ul className="list">
                            
                            <li className="listItem">
                                <PatreonButton />
                            </li>
                        </ul>
                    <br />



                </div>

            </div>
        </div>
    )

}

export default TheProject;