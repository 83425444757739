import React from 'react';
import '../css/buttons.css';
import '../App.css';

const EditAudioButton = ({ onClick, label, isActive }) => {
    return (
        <button className={`svg-button ${isActive ? 'svg-button-active' : ''}`} onClick={onClick}>

            <svg className="svg-InColumn" style={{ fill: 'none' }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 19C9 20.6569 7.65685 22 6 22C4.34315 22 3 20.6569 3 19C3 17.3431 4.34315 16 6 16C7.65685 16 9 17.3431 9 19Z" stroke-width="1"></path>
                <path d="M21 17C21 18.6569 19.6569 20 18 20C16.3431 20 15 18.6569 15 17C15 15.3431 16.3431 14 18 14C19.6569 14 21 15.3431 21 17Z" stroke-width="1"></path>
                <path d="M9 19V8" stroke-width="1"></path>
                <path d="M21 17V6" stroke-width="1"></path>
                <path d="M15.7351 3.75466L11.7351 5.08799C10.4151 5.52801 9.75503 5.74801 9.37752 6.27179C9 6.79556 9 7.49128 9 8.88273V11.9997L21 7.99969V7.54939C21 5.01693 21 3.7507 20.1694 3.15206C19.3388 2.55341 18.1376 2.95383 15.7351 3.75466Z" stroke-width="1" stroke-linecap="round"></path>
            </svg>
            {/*    {label}*/}
        </button>
    );
}

export default EditAudioButton;
