import React from 'react';

function Lab1(props) {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill: 'white', height: '1.8em', width: '1.8em' }}
            viewBox="0 0 48 60"
            preserveAspectRatio="xMidYMid meet"
        >
            <g>
                <path d="M29.25,20.651v-6.103c1.206-0.382,2.125-0.986,2.125-1.955C31.375,10.188,25.73,10,24,10s-7.375,0.188-7.375,2.594 c0,0.969,0.919,1.573,2.125,1.955v6.103c-4.794,2.073-7.969,6.861-7.969,12.13C10.781,40.07,16.711,46,24,46   s13.219-5.93,13.219-13.219C37.219,27.513,34.044,22.725,29.25,20.651z M24,12c2.313,0,3.959,0.304,4.823,0.594   c-0.864,0.29-2.51,0.594-4.823,0.594s-3.959-0.304-4.823-0.594C20.041,12.304,21.687,12,24,12z M20.097,22.265   c0.392-0.145,0.653-0.519,0.653-0.938v-6.349c1.301,0.178,2.574,0.209,3.25,0.209s1.949-0.031,3.25-0.209v6.349   c0,0.419,0.261,0.793,0.653,0.938c4.106,1.52,6.948,5.335,7.282,9.659c-2.597,0.307-4.853-0.396-7.223-1.146   c-3.896-1.233-8.293-2.615-15.015,0.117C13.618,27.004,16.32,23.662,20.097,22.265z M24,44c-6.068,0-11.015-4.846-11.201-10.871   c6.58-2.967,10.637-1.686,14.56-0.444c2.063,0.653,4.18,1.324,6.573,1.324c0.4,0,0.809-0.02,1.226-0.061   C34.571,39.589,29.792,44,24,44z M21,7c0-0.553,0.448-1,1-1s1,0.447,1,1s-0.448,1-1,1S21,7.553,21,7z M20,26c0-0.553,0.448-1,1-1   s1,0.447,1,1s-0.448,1-1,1S20,26.553,20,26z M25,23c0.552,0,1,0.447,1,1s-0.448,1-1,1s-1-0.447-1-1S24.448,23,25,23z M23,19   c0-0.553,0.448-1,1-1s1,0.447,1,1s-0.448,1-1,1S23,19.553,23,19z M34,13c1.93,0,3.5-1.57,3.5-3.5S35.93,6,34,6s-3.5,1.57-3.5,3.5   S32.07,13,34,13z M34,8c0.827,0,1.5,0.673,1.5,1.5S34.827,11,34,11s-1.5-0.673-1.5-1.5S33.173,8,34,8z M27,8c1.654,0,3-1.346,3-3   s-1.346-3-3-3s-3,1.346-3,3S25.346,8,27,8z M27,4c0.551,0,1,0.448,1,1s-0.449,1-1,1s-1-0.448-1-1S26.449,4,27,4z" />
</g>
</svg>





    );
}

export default Lab1;
