import React from 'react';
import '../css/buttons.css';
import '../App.css';

const EditExportButton = ({ onClick, label, isActive }) => {
    return (
        <button className={`svg-button ${isActive ? 'svg-button-active' : ''}`} onClick={onClick}>


            <svg
                className="svg-InColumn" 
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 122.88 110.35"
                style={{ enableBackground: 'new 0 0 122.88 110.35' }}
                xmlSpace="preserve"
            >
                <g>
                    <path
                        d="M6.32,0v6.22h12.22V0h6.32v14.88h73.15V0h6.32v6.22h12.22V0h6.32v110.35h-6.32v-7.19h-12.22v7.19h-6.32V94.79H24.87v15.56 h-6.32v-7.19H6.32v7.19H0V0H6.32L6.32,0z M47.98,66.78l-2.37-5.38l-2.85,0.09v10.46h37.36l0-10.46l-2.08-0.09l-3.33,5.38H47.98 L47.98,66.78z M66.85,61.46h-10.5v-8.78H46.9l14.69-14.29L76.3,52.68h-9.45V61.46L66.85,61.46L66.85,61.46z M98.01,21.2H24.87 v67.27h73.15V21.2L98.01,21.2z M116.56,96.84v-11.8h-12.22v11.8H116.56L116.56,96.84z M116.56,78.72v-11.8h-12.22v11.8H116.56 L116.56,78.72z M116.56,60.59v-11.8h-12.22v11.8H116.56L116.56,60.59z M116.56,42.47v-11.8h-12.22v11.8H116.56L116.56,42.47z M116.56,24.35v-11.8h-12.22v11.8H116.56L116.56,24.35z M18.54,96.84v-11.8H6.32v11.8H18.54L18.54,96.84z M18.54,78.72v-11.8H6.32 v11.8H18.54L18.54,78.72z M18.54,60.59v-11.8H6.32v11.8H18.54L18.54,60.59z M18.54,42.47v-11.8H6.32v11.8H18.54L18.54,42.47z M18.54,24.35v-11.8H6.32v11.8H18.54L18.54,24.35z" />
                </g>
            </svg>




            {/*    {label}*/}
        </button>
    );
}

export default EditExportButton;
