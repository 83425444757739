import React, { useState, useEffect, useRef, useCallback } from "react";
import { fetchFile } from '@ffmpeg/ffmpeg';
import "./Style.css";
import "../App.css";
import { useFFmpeg } from './FFmpegContext.jsx';
import ProgressBar from './ProgressBar.jsx';
import { Bounce, Levels } from "react-activity";
import "react-activity/dist/library.css";
import ExportVideo from "../img/ExportVideo.png";
import { transliterate } from 'transliteration';
import { useRendering } from './RenderingContext';

const paidAccount = false; // Define paidAccount based on your application's logic
const originalSpeed = 2; // Define originalSpeed based on your application's logic

// Define Particle class outside the component
class Particle {
    constructor(x, y, speed, size, color, canvasRef) {
        this.x = x;
        this.y = y;
        this.speed = speed;
        this.baseSpeed = speed;
        this.maxSpeed = speed * 5;
        this.size = size;
        this.color = color;
        this.canvasRef = canvasRef; // Store canvasRef in the instance
    }

    onBeat() {
        this.speed += 5;
        this.speed = Math.min(this.speed, this.maxSpeed);
    }

    update() {
        this.x += this.speed;
        if (this.speed > this.baseSpeed) {
            this.speed -= 1;
        }
        this.speed = Math.max(this.speed, this.baseSpeed);
        if (this.x - this.size > this.canvasRef.current.width) { // Use this.canvasRef
            this.resetParticle();
        }
    }

    resetParticle() {
        this.x = -this.size;
        this.y = Math.random() * this.canvasRef.current.height; // Use this.canvasRef
        this.speed = this.baseSpeed + Math.random() * 2;
    }

    draw(ctx) {
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
    }
}

// Define the drawing functions outside the component
function drawBackground(ctx, backgroundImage, canvasWidth, canvasHeight, currentRotation, color1, color2, hideBackgroundMask) {
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    ctx.save();
    ctx.translate(canvasWidth / 2, canvasHeight / 2);
    ctx.rotate(currentRotation);
    //let imageEnlarger = Math.floor(0.014 * canvasHeight);
    let imageEnlarger = 0;
    if (!paidAccount) {
        imageEnlarger = 0;
    }
    ctx.drawImage(backgroundImage, -(canvasWidth + imageEnlarger) / 2, -(canvasHeight + imageEnlarger) / 2,
        canvasWidth + imageEnlarger, canvasHeight + imageEnlarger);




    ctx.shadowColor = color2.color;
    ctx.shadowBlur = color2.opacity*2; // Increase the blur radius for a more pronounced glow

    if (hideBackgroundMask === false) {
        ctx.globalAlpha = color1.opacity; //opacity
    }
    else if (hideBackgroundMask === true) {
        ctx.globalAlpha = 0; //opacity
    }


    ctx.strokeStyle = color2.color; // Match stroke color with the glow for consistency
    ctx.lineWidth = 30; // Border thickness


    // Enhance the glow effect by drawing the border multiple times
    for (let i = 0; i < 3; i++) {
        // Step 3: Draw the rectangle around the image
        ctx.strokeRect(-(backgroundImage.width + ctx.lineWidth) / 2, -(backgroundImage.height + ctx.lineWidth) / 2,
            backgroundImage.width + ctx.lineWidth, backgroundImage.height + ctx.lineWidth);
    }


    // Reset shadow properties to avoid affecting subsequent drawings
    ctx.shadowColor = 'transparent';

    ctx.shadowBlur = 0;
    ctx.restore();





    ctx.restore();
}




function drawCover(ctx, coverImage, canvasWidth, canvasHeight, currentRotation, currentScale, color1, color2, hideCoverMask, postId) {

    let centerX, centerY, scale;


    if (postId === 1) {
        scale = 0.8;

        // Calculate the rectangle dimensions
        centerX = canvasWidth / 2;
        centerY = canvasHeight / 2 - (canvasHeight) * 0.12;
    }

    else if (postId === 2) {
        scale = 0.9;
        centerX = canvasWidth * 0.25;
        centerY = canvasHeight / 2;
    }
    else if (postId === 3) {
        scale = 0.9;
        centerX = canvasWidth * 0.25;
        centerY = canvasHeight / 2;
    }



    const scaledWidth = coverImage.width * scale;
    const scaledHeight = coverImage.height * scale;
    ctx.save();
    ctx.translate(centerX, centerY);

    ctx.scale(currentScale, currentScale); // Apply dynamic scaling




    // Neon glow configuration for the border
    ctx.shadowColor = color2.color;
    ctx.shadowBlur = color2.opacity  * 2; // Increase the blur radius for a more pronounced glow
    if (hideCoverMask === false) {
        ctx.globalAlpha = color1.opacity; //opacity
    }
    else if (hideCoverMask === true) {
        ctx.globalAlpha = 0; //opacity
    }
    ctx.strokeStyle = color1.color; // Match stroke color with the glow for consistency
    ctx.lineWidth = Math.floor(20 ); // Border thickness
    //ctx.lineWidth = 30; // Border thickness


    // Enhance the glow effect by drawing the border multiple times
    for (let i = 0; i < 3; i++) {
        // Step 3: Draw the rectangle around the image
        //ctx.strokeRect(-scaledWidth / 2, -scaledHeight / 2, scaledWidth, scaledHeight);
        ctx.strokeRect(-(scaledWidth - ctx.lineWidth - 2) / 2, -(scaledHeight - ctx.lineWidth - 2) / 2,
            scaledWidth - ctx.lineWidth - 2, scaledHeight - ctx.lineWidth - 2);
    }


    // Reset shadow properties to avoid affecting subsequent drawings
    ctx.shadowColor = 'transparent';

    ctx.shadowBlur = 0;

    ctx.save();

    ctx.restore();

    ctx.globalAlpha = 1; //opacity



    ctx.drawImage(coverImage, -scaledWidth / 2, -scaledHeight / 2, scaledWidth, scaledHeight);


    ctx.restore();
}








const AudioVisualizer37fFREE = ({
    selectedFont, imgSourceBackground, imgSourceCover, handleWindowButtonClick,
    color1, color2, color3, minHueValue, maxHueValue, selectedAudioFile, postId,
    audioFiles, albumTracksNames, longestName, concatenatedVideoTitle, tracksAlignment, albumCheck, hideBackgroundMask, hideCoverMask,
    onConcatenatedVideo, setOutputVideo, outputVideo, setVideos, videos,
    setVideoTitles, videoTitles, 
    thereAreVideos, setThereAreVideos, latestFrameUrl, setLatestFrameUrl,
    totalVideos, setTotalVideos, totalTimeSeconds, setTotalTimeSeconds, totalFileSizeMB, setTotalFileSizeMB

}) => {
    const { isRendering, setIsRendering } = useRendering();
    const { ffmpeg, isFFmpegLoaded } = useFFmpeg();
    const canvasRef = useRef(null);
    const audioCtxRef = useRef(new (window.AudioContext || window.webkitAudioContext)());
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const audioRef = useRef(null);

    const [videosRendered, setVideosRendered] = useState(false);
    const [isAudioLoaded, setIsAudioLoaded] = useState(false);
    const [audioDuration, setAudioDuration] = useState(0);
    const [totalFrames, setTotalFrames] = useState(0);
    const [renderingVideo, setRenderingVideo] = useState('');
    const [frames, setFrames] = useState([]);
    //const [latestFrameUrl, setLatestFrameUrl] = useState(Black_img);
    const [activeWindowButton, setActiveWindowButton] = useState('AudioFiles');
    const [progress, setProgress] = useState(0);
    const [statusMessage, setStatusMessage] = useState('');

    const fps = 30;
    const frameInterval = 1000 / fps;
    const desiredWidth = 1280;
    const desiredHeight = 720;
    const barWidth = 15;

    useEffect(() => {
        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleBeforeUnload = useCallback((event) => {
        const message = 'Rendering is in progress. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
    }, []);

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const initParticles = (numParticles, ctx, canvasRef) => {
        let particles = [];
        for (let i = 0; i < numParticles; i++) {
            let startX = Math.random() * ctx.canvas.width - ctx.canvas.width;
            let startY = Math.random() * ctx.canvas.height;
            particles.push(new Particle(startX, startY, originalSpeed + Math.random() * 3, 1 + Math.random() * 3, `hsla(${Math.random() * 360}, 100%, 50%, 0.4)`));
        }
        return particles;
    };







    const adjustFontSizeForWidth = (ctx, text, initialFontSize, maxWidth, selectedFont, canvasWidth, canvasHeight,
        albumTracksNames, textId, tracksAlignment,albumCheck,minFontSize = 10) => {
       // console.log(longestName);


        let fontSize = initialFontSize;
        ctx.font = `${fontSize}px ${selectedFont}`;
        let textWidth = ctx.measureText(text).width;
        let textHeight = fontSize;


        let maxHeight;
        if (albumCheck === false) {
            maxHeight = Math.floor(canvasHeight * 1 / 2);
        }
        else if (albumCheck === true) {
            maxHeight = Math.floor(canvasHeight * 3 / 5);
        }





        // Function to calculate total list height
        const calculateListHeight = (fontSize, albumTracksNames) => {
            return fontSize * albumTracksNames.length;
        };

        if (postId === 1) {

            while (textWidth > maxWidth && fontSize > minFontSize) {
                fontSize--;
                ctx.font = `${fontSize}px ${selectedFont}`;
                textWidth = ctx.measureText(text).width;
            }
        }
        else if (postId === 2) {
            maxWidth = Math.round(maxWidth * 3 / 5);
            while (textWidth > maxWidth && fontSize > minFontSize) {
                fontSize--;
                ctx.font = `${fontSize}px ${selectedFont}`;
                textWidth = ctx.measureText(text).width;
            }
        }

        if (postId === 3) {
            maxWidth = Math.round(maxWidth * 4 / 5);
            if (textId === 'drawAlbumTitle') {
                while (textWidth > maxWidth && fontSize > minFontSize) {
                    fontSize--;
                    ctx.font = `${fontSize}px ${selectedFont}`;
                    textWidth = ctx.measureText(text).width;
                    textHeight = fontSize;
                }
            }
            else {
                //let longestWidth = ctx.measureText(longestName).width;
                
                while ((textWidth > maxWidth || calculateListHeight(fontSize, albumTracksNames) > maxHeight) && fontSize > minFontSize) {
                    fontSize--;
                    ctx.font = `${fontSize}px ${selectedFont}`;
                    textWidth = ctx.measureText(text).width;
                    textHeight = fontSize;
                }
            }

        }


        return fontSize;
    };








    function drawAlbumTitle(ctx, text, maxWidth, initialFontSize, canvasWidth, canvasHeight, selectedFont,
        color1, color2, color3, albumTracksNames, longestName, selectedAudioFile, textId, tracksAlignment, albumCheck, padding = 5) {
        // Adjust the font size to fit the text within maxWidth
        // const textId = 'drawAlbumTitle';
        const adjustedFontSize = adjustFontSizeForWidth(ctx, text, initialFontSize, maxWidth, selectedFont, textId, canvasWidth, canvasHeight,
            albumTracksNames);



        ctx.font = `${adjustedFontSize}px ${selectedFont}`;

        // Neon glow configuration
        ctx.shadowColor = color3.color;
        ctx.shadowBlur = color3.opacity; // Increase the blur radius for a more pronounced glow

        // Calculate text width and height with the adjusted font size
        const metrics = ctx.measureText(text);
        const textWidth = metrics.width;
        const textHeight = adjustedFontSize; // Approximation of text height



        // Calculate the rectangle dimensions
        const rectWidth = Math.min(maxWidth, textWidth); // Use the lesser of maxWidth or textWidth + padding
        const rectHeight = textHeight + padding * 2;

        let rectX;


        // Position the middle of the text box at the center of the canvas (x-axis)
        if (tracksAlignment === 'left') {
            rectX = canvasWidth / 2 - canvasWidth * 0.01;
        }
        else if (tracksAlignment === 'center') {
            rectX = (canvasWidth / 2 + canvasWidth / 4 - rectWidth / 2 - canvasWidth * 0.02);
        }
        else if (tracksAlignment === 'right') {
            rectX = (canvasWidth - rectWidth - canvasWidth * 0.03);
        }


        // Position the bottom of the text box just above the middle of the canvas (y-axis)
        const rectY = Math.round(canvasHeight * 0.15 + rectHeight / 2);


        // Draw the text over the rectangle
        // Adjust text x position to start from the left edge of the rectangle with padding
        const textX = rectX;
        const textY = rectY - padding; // Fine-tune based on the font


        ctx.globalAlpha = color1.opacity; //opacity
        ctx.fillStyle = color1.color; // Text color


        // Enhance the glow effect by drawing the text multiple times
        if (albumCheck === true) {
            for (let i = 0; i < 3; i++) {
                ctx.fillText('', textX, textY);
            }
        }
        else if (albumCheck === false) {
            for (let i = 0; i < 3; i++) {
                ctx.fillText(text, textX, textY);
            }
        }


        // Reset shadow properties to avoid affecting subsequent canvas drawings
        ctx.shadowColor = 'transparent';
        ctx.shadowBlur = 0;
    }











    const drawText = (ctx, text, maxWidth, initialFontSize, canvasWidth, canvasHeight, selectedFont, color1, color2, color3,
        verticalOffset, padding, textId, tracksAlignment, albumCheck,longestName, selectedAudioFile) => {

        let adjustedFontSize;

        if (postId === 1 || postId === 2) {
            adjustedFontSize = adjustFontSizeForWidth(ctx, text, initialFontSize, maxWidth, selectedFont, canvasWidth, canvasHeight,
                albumTracksNames, textId, tracksAlignment, albumCheck);

        }
        else if (postId === 3) {
            adjustedFontSize = adjustFontSizeForWidth(ctx, longestName, initialFontSize, maxWidth, selectedFont, canvasWidth, canvasHeight,
                albumTracksNames, textId, tracksAlignment, albumCheck);

        }


        ctx.font = `${adjustedFontSize}px ${selectedFont}`;
        ctx.shadowColor = color2.color;
        ctx.shadowBlur = color2.opacity;

        const metrics = ctx.measureText(text);
        const textWidth = metrics.width;
        const textHeight = adjustedFontSize;





        let rectX, rectY, rectWidth;


        if (postId === 1) {
             rectWidth = Math.min(maxWidth, textWidth);
            const rectHeight = textHeight + padding * 2;



            if (tracksAlignment === 'left') {
                rectX = canvasWidth * 0.03;
            }
            // Position the middle of the text box at the center of the canvas (x-axis)
            else if (tracksAlignment === 'center') {
                rectX = (canvasWidth - rectWidth) / 2;
            }
            else if (tracksAlignment === 'right') {
                rectX = (canvasWidth - rectWidth - canvasWidth * 0.03);
            }
            // Position the bottom of the text box just above the middle of the canvas (y-axis)
            rectY = (canvasHeight - (0.14 * canvasHeight)) + verticalOffset * (rectHeight - 3 * padding);  
        }


        if (postId === 2) {
             rectWidth = Math.min(maxWidth, textWidth);
            const rectHeight = textHeight + padding * 2;

            if (tracksAlignment === 'left') {
                rectX = canvasWidth / 2 - canvasWidth * 0.01;
            }
            // Position the middle of the text box at the center of the canvas (x-axis)
            else if (tracksAlignment === 'center') {
                rectX = (canvasWidth / 2 + canvasWidth / 4 - rectWidth / 2 - canvasWidth * 0.02);
            }
            else if (tracksAlignment === 'right') {
                rectX = (canvasWidth - rectWidth - canvasWidth * 0.03);
            }
            rectY = Math.round((canvasHeight / 2) + verticalOffset * (rectHeight - 4 * padding));
        }






        else if (postId === 3) {
            // Calculate the rectangle dimensions
             rectWidth = Math.min(maxWidth, textWidth); // Use the lesser of maxWidth or textWidth + padding
            const rectHeight = textHeight + padding * 2;

            // Position the middle of the text box at the center of the canvas (x-axis)
            //rectX = canvasWidth / 2;



            if (tracksAlignment === 'left') {
                rectX = canvasWidth / 2 - canvasWidth * 0.01;
            }
            // Position the middle of the text box at the center of the canvas (x-axis)
            else if (tracksAlignment === 'center') {
                rectX = (canvasWidth / 2 + canvasWidth / 4 - rectWidth / 2 - canvasWidth * 0.02);
            }
            else if (tracksAlignment === 'right') {
                rectX = (canvasWidth - rectWidth - canvasWidth * 0.03);

            }





            if (albumCheck === true) {
                rectY = Math.round(((canvasHeight - albumTracksNames.length * 1.2 * textHeight) / 2) + textHeight);
            }


            else if (albumCheck === false) {
                rectY = Math.round(((canvasHeight - albumTracksNames.length * 1.2 * textHeight) / 2) + textHeight + 0.07 * canvasHeight);
            }



        }



        let textX, textY;
        if (postId === 1 || postId === 2) {
            textX = rectX;
            textY = rectY - padding; // Fine-tune based on the font
        }
        else if (postId === 3) {
            textX = rectX;
            textY = rectY - padding; // Fine-tune based on the font
        }




        ctx.globalAlpha = color1.opacity;
        ctx.fillStyle = color1.color;

        for (let i = 0; i < 3; i++) {
            if (postId === 1) {
                ctx.fillText(text, textX, textY);
            }
            else if (postId === 2 && textId === 'drawSingleLineTextCentered') {
                ctx.fillText(text, textX, textY);
            }
            else if (postId === 2 && textId === 'drawSingleLineTextCentered2') {
                ctx.fillText(text, textX, textY + 20);
            }
            else if (postId === 3) {
                // Draw each name
                albumTracksNames.forEach((name, index) => {
                    if (albumTracksNames[index] === selectedAudioFile.name) {
                        ctx.shadowColor = color3.color;
                        ctx.shadowBlur = color3.opacity;

                    }
                    else {
                        ctx.shadowColor = color2.color;
                        ctx.shadowBlur = color2.opacity;
                    }


                    let metrics2 = ctx.measureText(albumTracksNames[index]);
                    let textWidth2 = metrics2.width;

                    if (tracksAlignment === 'center') {
                        // console.log('center');
                        ctx.fillText(name, textX + (rectWidth - textWidth2) / 2, textY + index * textHeight * 1.2);
                    }
                    else if (tracksAlignment === 'left') {
                        ctx.fillText(name, textX, textY + index * textHeight * 1.2);
                    }

                    else if (tracksAlignment === 'right') {
                        ctx.fillText(name, textX + rectWidth - textWidth2, textY + index * textHeight * 1.2);
                    }


                });
            }
        }

        ctx.shadowColor = 'transparent';
        ctx.shadowBlur = 0;
    };

    const loadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = src;
        });
    };






    const createAndCaptureVisual = async () => {
        setStatusMessage('Drawing Canvas ');

        // Load the images
        let backgroundImage, coverImage;
        try {
            backgroundImage = await loadImage(imgSourceBackground);
            coverImage = await loadImage(imgSourceCover);
        } catch (error) {
            console.error('Error loading images:', error);
            return;
        }

        for (let x = 0; x < audioFiles.length; x++) {
            setRenderingVideo(`${audioFiles[x].name}`);
            try {
                const delayTime = 200;

                const canvas = canvasRef.current;
                const ctx = canvas.getContext('2d');
                //const scale = window.devicePixelRatio;
                const scale = 1;
                canvas.width = desiredWidth * scale;
                canvas.height = desiredHeight * scale;
                ctx.scale(scale, scale);
                ctx.lineWidth = scale;

                const samplesPerFrame = audioCtxRef.current.sampleRate / fps;
               // let particles = paidAccount ? initParticles(100, ctx, canvasRef) : [];

                const numberOfFrames = paidAccount ? totalFrames : 2;

                for (let i = 1; i < numberOfFrames; i++) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);

                    const startSample = Math.floor(i * samplesPerFrame);
                    const endSample = Math.floor((i + 1) * samplesPerFrame);

                    let rotationAngle = 0;
                    let currentScale = 1;


                    drawBackground(ctx, backgroundImage, canvas.width, canvas.height, rotationAngle, color1, color2, hideBackgroundMask);
                    drawCover(ctx, coverImage, canvas.width, canvas.height, rotationAngle, currentScale, color1, color2, hideCoverMask, postId);


                    let artistText = audioFiles[x].artist;
                    let songText = audioFiles[x].title;

                    const maxWidthArtist = canvas.width * 4 / 5; // Set max width as needed
                    const maxWidthSong = canvas.width * 3 / 5; // Set max width as needed
                    const maxWidthTracklist = canvas.width * 3 / 5; // Set max width as needed

                    const initialFontSize = 150;
                    const initialFontSize2 = 80;
                    let initialFontSize3 = 100;



                    if (postId === 1 || postId === 2) {
                        drawText(ctx, artistText, maxWidthArtist, initialFontSize, canvas.width, canvas.height, selectedFont,
                            color1, color2, color3, 0, 5, 'drawSingleLineTextCentered', tracksAlignment, albumCheck);

                        drawText(ctx, songText, maxWidthSong, initialFontSize2, canvas.width, canvas.height, selectedFont,
                            color1, color2, color3, 1, 5, 'drawSingleLineTextCentered2', tracksAlignment, albumCheck);
                    }

                    else if (postId === 3) {

                        drawText(ctx, artistText, maxWidthTracklist, initialFontSize3, canvas.width, canvas.height, selectedFont,
                            color1, color2, color3, 0, 5, 'drawSingleLineTextCentered', tracksAlignment,albumCheck,longestName,audioFiles[x]);


                        drawAlbumTitle(ctx, concatenatedVideoTitle, maxWidthTracklist, initialFontSize3, canvas.width, canvas.height, selectedFont,
                            color1, color2, color3, albumTracksNames, longestName, audioFiles[x], 'drawAlbumTitle', tracksAlignment, albumCheck);
                    }
                    

                    await captureAndDownloadCanvas(i, x);
                }
            } catch (error) {
                console.error('Error in createAndCaptureVisual:', error);
            }
            await delay(1000);
        }

        if (audioFiles.length > 1) {
            setVideosRendered(true);
        } else {
            setIsRendering(false);
        }

        setisSingleVideosRendered(true);

    };








    const captureAndDownloadCanvas = async (i, x) => {
        const canvas = canvasRef.current;
        const newFrame = await new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (latestFrameUrl) {
                    URL.revokeObjectURL(latestFrameUrl);
                }
                const url = URL.createObjectURL(blob);
                setLatestFrameUrl(url);
                resolve(blob);
            }, 'image/png');
        });
        setFrames(prevFrames => [...prevFrames, newFrame]);
        const updatedFrames = [...frames, newFrame];
      

        await renderVideo(updatedFrames, x);

        // Clean up: Revoke object URLs and clear frames array if necessary
        updatedFrames.forEach(frame => URL.revokeObjectURL(URL.createObjectURL(frame)));
        setFrames([]); // Clear frames array if no longer needed
    };




    const [isSingleVideosRendered, setisSingleVideosRendered] = useState(false);  // Flag to track if all videos have been rendered





    const renderVideo = async (frames, x) => {
        setStatusMessage('Initializing ');
        //setProgress(0);

        try {
            if (!ffmpeg.isLoaded()) {
                await ffmpeg.load();
            }

            if (!audioFiles[x]) {
                throw new Error(`Audio file at index ${x} not found`);
            }

            ffmpeg.FS('writeFile', 'sound' + x + '', await fetchFile(audioFiles[x].source));

            const numberOfFrames = paidAccount ? totalFrames : 1;

            for (let i = 0; i < numberOfFrames; i++) {
                const frameData = await frames[i].arrayBuffer();
                const frameNumberPadded = String(i + 1).padStart(3, '0');
                ffmpeg.FS('writeFile', `frame_${frameNumberPadded}.png`, new Uint8Array(frameData));
            }

            const outputVideoName = `${audioFiles[x].name}.mp4`;
            const safeTitle = transliterate(audioFiles[x].name);
            const outputVideoNameSafe = `${safeTitle}.mp4`;

            setVideoTitles(f => [...f, outputVideoName]);






            const parseTime = (timeString) => {
                const parts = timeString.split(':');
                return parseFloat(parts[0]) * 3600 + parseFloat(parts[1]) * 60 + parseFloat(parts[2]);
            };


            //const soundDuration = (Math.round(audioFiles[x].duration)).toString();
            const soundDuration = (audioFiles[x].duration).toString();



            ffmpeg.setLogger(({ message }) => {
                const timeMatch = message.match(/time=([0-9:.]+)/);
                if (timeMatch) {
                    const currentTime = parseTime(timeMatch[1]);
                    const ratio = currentTime / soundDuration;
                    if (ratio > 0) {
                        setStatusMessage('Rendering Started ');
                    }
                    setProgress(Math.min(ratio * 100, 100));
                }


            });








            await ffmpeg.run(
                '-loop', '1',                // Loop the image
                '-framerate', '2',           // Lower the frame rate to 5 fps
                '-i', 'frame_001.png',       // Input image
                '-i', `sound${x}`,         // Input audio
                '-c:v', 'libx264',           // Video codec
                '-preset', 'ultrafast',      // Use 'ultrafast' preset for speed
                '-crf', '23',                // Higher CRF value to reduce quality slightly and speed up processing
                '-movflags', 'faststart',    // Optimize for web playback
                '-tune', 'stillimage',       // Tune for still images
                //'-c:a', 'aac',               // Audio codec
                //'-b:a', '128k',              // Lower audio bitrate
                //'-c:a', 'copy',              // Copy audio stream without re-encoding
                '-t', soundDuration,  // Set the duration to match the audio length
                '-pix_fmt', 'yuv420p',       // Pixel format
                outputVideoNameSafe          // Output file name
            );


            //[fferr] frame = 46 fps = 2.0 q = 4.0 size = 1536kB time = 00: 00: 19.00 bitrate = 662.3kbits / s speed = 0.816x   



            setProgress(0);


            setStatusMessage('Rendering completed successfully!');

            const data = ffmpeg.FS('readFile', outputVideoNameSafe);
            const videoBlob = new Blob([data.buffer], { type: 'video/mp4' });
            const videoSrc = URL.createObjectURL(videoBlob);

            const fileStats = ffmpeg.FS('stat', outputVideoNameSafe);
            const fileSize = fileStats.size; // Size in bytes

            setVideos(v => [...v, { url: videoSrc, title: outputVideoName, duration: audioFiles[x].duration, size: fileSize }]);
            setThereAreVideos(true);

            ffmpeg.FS('unlink', 'sound' + x);
            for (let i = 0; i < numberOfFrames; i++) {
                const frameNumberPadded = String(i + 1).padStart(3, '0');
                ffmpeg.FS('unlink', `frame_${frameNumberPadded}.png`);
            }

            await ffmpeg.FS('unlink', outputVideoNameSafe);
            URL.revokeObjectURL(videos);

        } catch (error) {
            console.error('Error processing video:', error);
            setStatusMessage('An error occurred during rendering.');
        }
    };





    const calculateTotal = (videos) => {
        const totalVideos = videos.length;
        const totalDuration = videos.reduce((sum, video) => sum + video.duration, 0);  // Sum up durations
        const totalFileSize = videos.reduce((sum, video) => sum + video.size, 0);      // Sum up file sizes (in bytes)

        const totalFileSizeMB = (totalFileSize / (1024 * 1024)).toFixed(2);  // Convert to MB
        //const totalTimeMinutes = (totalDuration / 60).toFixed(2);            // Convert to minutes
        const totalTimeSeconds = (totalDuration).toFixed(2);            // Convert to minutes

        return { totalVideos, totalTimeSeconds, totalFileSizeMB };
    };



    useEffect(() => {
        // Whenever the 'videos' state changes, calculate the totals and log them
        if (isSingleVideosRendered === true) {
            const { totalVideos, totalTimeSeconds, totalFileSizeMB } = calculateTotal(videos);
            //console.log(`Total Videos: ${totalVideos}, Total Time: ${totalTimeMinutes} seconds, Total File Size: ${totalFileSizeMB} MB`);
            setTotalVideos(totalVideos);
            setTotalTimeSeconds(totalTimeSeconds);
            setTotalFileSizeMB(totalFileSizeMB);
            setisSingleVideosRendered(false);
        }
    }, [isSingleVideosRendered]);  // Only runs when 'videos' state changes







    const concatVideos = async () => {
        setStatusMessage('Initializing ');
        setRenderingVideo(`${concatenatedVideoTitle}`);
        setProgress(0);

        if (!ffmpeg.isLoaded()) {
            await ffmpeg.load();
        }

        const fileList = 'filelist.txt';
        let fileListContent = '';
        let fileNames = [];

        for (let i = 0; i < videos.length; i++) {
            const video = videos[i];
            const fileName = `input${i}.mp4`;
            fileNames.push(fileName);
            const response = await fetch(video.url);
            const videoArrayBuffer = await response.arrayBuffer();
            ffmpeg.FS('writeFile', fileName, new Uint8Array(videoArrayBuffer));
            fileListContent += `file '${fileName}'\n`;
        }

        await ffmpeg.FS('writeFile', fileList, fileListContent);



        ffmpeg.setProgress(({ ratio }) => {
            const jobProgress = Math.round(ratio * 100);
            //setStatusMessage(ratio > 0 ? 'Rendering Started ' : '');
                setStatusMessage('Rendering Started ');
            setProgress(ratio < 0 || ratio > 1 || isNaN(ratio) || !isFinite(ratio) ? 0 : jobProgress);
        });

        await ffmpeg.run(
            '-f', 'concat',
            '-safe', '0',
            '-i', fileList,
            '-c', 'copy',
            'output.mp4');





        setStatusMessage('All videos rendered successfully!');

        const outputData = ffmpeg.FS('readFile', 'output.mp4');
        const outputVideoBlob = new Blob([outputData.buffer], { type: 'video/mp4' });
        const outputVideoUrl = URL.createObjectURL(outputVideoBlob);

        setIsRendering(false);
        window.removeEventListener('beforeunload', handleBeforeUnload);
        if (onConcatenatedVideo) {
            onConcatenatedVideo(outputVideoUrl);
        }

        await ffmpeg.FS('unlink', fileList);
        fileNames.forEach(file => ffmpeg.FS('unlink', file));
        try {
            ffmpeg.FS('unlink', 'output.mp4');
        } catch (e) {
            console.error("Error cleaning up output file:", e);
        }
    };

    useEffect(() => {
        if (videosRendered) {
            concatVideos();
            setVideosRendered(false);
        }
    }, [videosRendered, videos, onConcatenatedVideo]);

    const handleRenderButtonClick = async () => {
        if (isRendering) {
            alert('Rendering is in progress. Please wait until it is complete.');
            return;
        }
        if ((!concatenatedVideoTitle || concatenatedVideoTitle.trim() === '') && audioFiles.length > 1) {
            alert('Please enter an Album Title first. Go to the first tab "Edit Audio Files".');
            return;
        }
        if (videos.length > 0) {
            const userConfirmed = window.confirm('All previous Videos will be lost. Do you wish to continue?');
            if (!userConfirmed) {
                return;
            }
        }

        setVideos([]);
        setVideoTitles([]);
        setFrames([]);
        setVideosRendered(false);
        onConcatenatedVideo(null);
        setIsRendering(true);
        window.addEventListener('beforeunload', handleBeforeUnload);

        await createAndCaptureVisual();
    };





        const [isFullscreen, setIsFullscreen] = useState(false);

        const handleImageClick = () => {
            setIsFullscreen(!isFullscreen);
        };

        const fullscreenStyle = {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            cursor: 'pointer',
            zIndex: 1000,
        };

        const normalStyle = {
            width: '100%',
            height: 'auto',
            cursor: 'pointer',
        };




    return (
        <div>
            <div>
                {audioDuration > 0 && <p>Duration: {audioDuration.toFixed(2)} seconds</p>}
                {totalFrames > 0 && <p>Total Frames: {totalFrames.toFixed(2)}</p>}







                <div >

                    <li className="columns-container" >
                        <div className="textUploadAudio" >
                            <div>

                            <span style={{ fontSize: '2em' }}>1. </span>
                            <i><b>Export </b></i>
                            <span className="simpleText">to Videos</span>
                            </div>
                        </div>



                        <div className="column35">

                            <div style={{
                                paddingTop: '10px', display: 'flex', flexDirection: 'column',
                                alignItems: 'center', justifyContent: 'space-between'
                            }}>

                             <button onClick={handleRenderButtonClick} className="btn-with-icon">
                                    <img src={ExportVideo} alt="icon" className="btn-icon" />
                                    Export Videos
                                </button>
                                <span>{audioFiles.length > 0 ? ` ${audioFiles.length} Files Selected` : ' No Files Chosen'}</span>
                                
                            </div>

                        </div>
                    </li>


                </div>










                <div className="blueLine"></div>



                <div style={{ display: 'flex', justifyContent: 'left', paddingTop: '10px' }}>

                    <li className="columns-container" >
                        <div className="textUploadAudio" >

                            <span style={{ fontSize: '2em' }}>2. </span>
                            <i><b>Wait </b></i>
                            <span className="simpleText">until your videos are rendered</span>
                        </div>



                    </li>

                </div>




                <div>
                    <div className="containerUploadAudio">
                        <span className="textUploadAudio">


                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                                <div className="textUploadAudio">
                                    <h2>Frames Preview</h2>
                                    <div style={{ paddingTop: '10px' }}>
                                        <img
                                            src={latestFrameUrl}
                                            alt="Most recent frame"
                                            style={isFullscreen ? fullscreenStyle : normalStyle}
                                            onClick={handleImageClick}
                                        />
                                    </div>
                                </div>
                                <div className="textUploadAudio">
                                    <div className="progress-bar-container">
                                       
                                        <h2>Track Progress</h2>
                                        <br />
                                        <br />
                                        <ProgressBar progress={progress.toFixed(0)} />
                                      


                                        <br />


                                        <p>Now Rendering: <span className="simpleText">{renderingVideo}</span></p>
                                        <br />
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                            <span>{statusMessage}</span>
                                            {statusMessage === 'Drawing Canvas ' || statusMessage === 'Initializing ' ? <Bounce size={10} speed={1} animating /> : null}
                                            {statusMessage === 'Rendering Started ' ? <Levels size={10} speed={1} animating /> : null}
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <canvas hidden ref={canvasRef} id="canvas1"></canvas>
            </div>
            <br />
        </div>
    );
};

export default AudioVisualizer37fFREE;
