// FFmpegContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { createFFmpeg } from '@ffmpeg/ffmpeg';

// Create FFmpeg instance
const ffmpeg = createFFmpeg({ log: true });

// Create a Context
const FFmpegContext = createContext();

// Provider component
export const FFmpegProvider = ({ children }) => {
    const [isFFmpegLoaded, setIsFFmpegLoaded] = useState(false);

    const load = async () => {
        if (!ffmpeg.isLoaded()) {
            await ffmpeg.load();
            setIsFFmpegLoaded(true);
        }
    };

    // Load FFmpeg when the provider is mounted
    useEffect(() => {
        load();
    }, []);

    return (
        <FFmpegContext.Provider value={{ ffmpeg, isFFmpegLoaded }}>
            {children}
        </FFmpegContext.Provider>
    );
};

// Custom hook to use the FFmpeg context
export const useFFmpeg = () => useContext(FFmpegContext);
