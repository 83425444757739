import React, { createContext, useState, useContext } from 'react';

// Create a Context for the rendering state
const RenderingContext = createContext();

export const useRendering = () => useContext(RenderingContext);

export const RenderingProvider = ({ children }) => {
    const [isRendering, setIsRendering] = useState(false);

    return (
        <RenderingContext.Provider value={{ isRendering, setIsRendering }}>
            {children}
        </RenderingContext.Provider>
    );
};
