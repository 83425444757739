// HomeSection1.jsx
import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Template1 from '../../assets/images/Template1.webp';
import Template2 from '../../assets/images/Template2.webp';
import Template3 from '../../assets/images/Template3.webp';

const HeroSection = () => {
    return (
        <section className="home-section">
            <header>
                <h1 className="home-section-title">
                    Transform Your Music Album into Video Effortlessly!
                </h1>
                <p className="home-section-description">
                    Album Animator is an innovative tool for converting entire music albums
                    into professional videos with just a few simple clicks.
                </p>
                {/* Add Image */}
                <div>
                    <Link to={`/post/1`} className="link">
                        <img
                            src={Template3}
                            alt="Free online video maker for album art animation"
                            className="home-section-image"
                            loading="lazy" /* Enable lazy loading for performance */
                        />
                    </Link>
                </div>

                <div>
                    <Link className="link" to={`/post/1`}>
                        <button className="sectionButton">Start for Free!</button>
                    </Link>
                </div>
            </header>





        </section>
    );
};

export default HeroSection;
