// HomeSection1.jsx
import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Template1 from '../../assets/images/Template1.webp';
import Template2 from '../../assets/images/Template2.webp';
import Template3 from '../../assets/images/Template3.webp';

const Features = () => {
    return (
        <section className="home-section">
            <header>
                <h1 className="home-section-title">
                    Why Choose Album Animator?
                </h1>
                <p className="home-section-description">
                    Automatic syncing, customizable effects, and high-quality output make
                    video creation an easy task.
                </p>
                {/* Add Image */}
                {/*<img*/}
                {/*    src={Template3}*/}
                {/*    alt="Music album visualization example"*/}
                {/*    className="home-section-image"*/}
                {/*    loading="lazy" */}
                {/*/>*/}


            </header>


            <br></br>


            <section>
                <h2 className="home-section-title2">Features</h2>
                <p className="home-section-description">
                    <span style={{ color: '#007bff' }}>Easy Upload:</span> Upload your entire music album in one go.
                </p>
                <p className="home-section-description">
                    <span style={{ color: '#007bff' }}>Automatic Rendering:</span> Our advanced algorithms render your music into visually captivating videos.
                </p>
                <p className="home-section-description">
                    <span style={{ color: '#007bff' }}>Automatic Title Extraction:</span> Our algorithm automatically detects and separates the artist's name and song title from your uploaded audio, saving you time and effort.
                </p>
                <p className="home-section-description">
                    <span style={{ color: '#007bff' }}>Customization:</span> Choose from a variety of templates and visual styles to match your album’s vibe.
                </p>
                <p className="home-section-description">
                    <span style={{ color: '#007bff' }}>High Quality:</span> Produce high-definition videos suitable for sharing on all major platforms.
                </p>

            </section>
            <div>
                <br></br>

                <Link className="link" to={`/post/1`}>
                    <button className="sectionButton">SignUp for Free!</button>
                </Link>
            </div>

        </section>
    );
};

export default Features;
