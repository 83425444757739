import React from 'react';

const PatreonButton = () => {
    const patreonLink = 'https://www.patreon.com/AlbumAnimator'; // Replace with your actual Patreon page link

    return (
        <button
            onClick={() => window.open(patreonLink, '_blank')}
            style={{
                backgroundColor: '#FF424D',
                color: '#FFF',
                border: 'none',
                padding: '5px 10px',
                fontSize: '16px',
                borderRadius: '5px',
                cursor: 'pointer'
            }}
        >
            Support Us on Patreon
        </button>
    );
};

export default PatreonButton;
