import React, { useEffect } from 'react';
import { posts } from "../templatesData";
import Card from "../components/Card";
import '../App.css';





const TermsOfService = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="home">

            <div className="simplePage-container">
                <div className="privacy-policy">
                    <h2>Terms of Service</h2>
                    <br />
                    <div className="desc">
                        <p>Welcome to Album Animator. By accessing or using our web app,
                            you agree to comply with and be bound by the following terms and conditions
                            of service. Please review these terms carefully.
                            If you do not agree to these terms, you should not use this web app.</p>
                    </div>
                    <br />

                    <h3>1. Acceptance of Terms</h3>
                    <br />
                    <div className="desc">
                        <p>By using Album Animator, you agree to comply with and be bound by these Terms of Service and our Privacy Policy.
                            These terms apply to all users, including visitors, registered users, and contributors.</p>

                    </div>
                    <br />

                    <h3>2. Use of Album Animator</h3>
                    <br />
                    <div className="desc">
                        <p>Album Animator is provided to create videos for both personal and commercial use.
                            By using the app, you agree that you will not:</p>
                        <ul>
                            <li>Use the app for any illegal or unauthorized purpose</li>
                            <li>Interfere with or disrupt the functionality of Album Animator</li>
                            <li>Attempt to access the app’s backend servers or database</li>
                            <li>Use the app to create or distribute content that is defamatory, obscene, unlawful, or infringing on others' rights.</li>
                        </ul>
                    </div>
                    <br />

                    <h3>3. User-Uploaded Content</h3>
                    <br />
                    <div className="desc">
                        <p>Album Animator allows you to upload audio and image files to create videos. By uploading content, you represent and warrant that:</p>
                        <ul>
                            <li>You have all necessary rights, permissions, and licenses to upload and use the content</li>
                            <li>The content does not infringe any third-party intellectual property rights, privacy rights, or other legal rights</li>
                            <li>You will be solely responsible for your uploaded content, including its legality, reliability, and appropriateness.</li>
                        </ul>
                        <p>We reserve the right to remove or disable access to any content uploaded by users if we believe it violates these Terms of Service.</p>
                    </div>
                    <br />

                    <h3>4. Ownership and Intellectual Property</h3>
                    <br />
                    <div className="desc">
                        <p>All intellectual property rights in the Album Animator web app, including text, design, graphics, and software, belong to Album Animator or its licensors. You are granted a limited, non-exclusive, non-transferable license to use the app for both personal and commercial purposes, as outlined in these Terms of Service.</p>
                    </div>
                    <br />

                    <h3>5. Disclaimer and Limitation of Liability</h3>
                    <br />
                    <div className="desc">
                        <p>Album Animator is provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not guarantee that the app will be available at all times, uninterrupted, or error-free, nor do we warrant the accuracy or completeness of any content or information available on the app.</p>
                        <p>To the fullest extent permitted by law, Album Animator and its affiliates, employees, agents, and partners are not liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use of the app, even if we have been advised of the possibility of such damages.</p>
                    </div>
                    <br />

                    <h3>6. Indemnification</h3>
                    <br />
                    <div className="desc">
                        <p>You agree to indemnify, defend, and hold Album Animator harmless from and against any claims, liabilities, damages, losses, or expenses, including legal fees and costs, arising from your use of the app, your uploaded content, or any violation of these Terms of Service.</p>
                    </div>
                    <br />

                    <h3>7. Changes to Terms</h3>
                    <br />
                    <div className="desc">
                        <p>We reserve the right to modify these Terms of Service at any time. Changes will be posted on this page, and it is your responsibility to review these terms periodically. Continued use of Album Animator after any such changes constitutes your acceptance of the revised terms.</p>
                    </div>
                    <br />

                    <h3>8. Governing Law</h3>
                    <br />
                    <div className="desc">
                        <p>These Terms of Service are governed by and construed in accordance with the laws of Greece. By using Album Animator, you agree that any disputes arising out of or related to your use of the app will be subject to the exclusive jurisdiction of the courts in Greece, unless otherwise required by applicable law.</p>
                    </div>
                    <br />

                    <h3>9. Contact Information</h3>
                    <br />
                    <div className="desc">
                        <p>If you have any questions regarding these Terms of Service, please contact us at: </p>
                        <p> <b>Email:</b> jyro@albumanimator.io</p>
                    </div>
                    <br />


                </div>

            </div>
        </div>
    )

}

export default TermsOfService;