import { useLocation } from "react-router-dom";
import { posts } from "../templatesData";
import MainFunction_Tier_0 from '../components/MainFunction_Tier_0.jsx';



const Post = ({ thereAreVideos, setThereAreVideos,
    totalVideos, setTotalVideos, totalTimeSeconds, setTotalTimeSeconds, totalFileSizeMB, setTotalFileSizeMB,
    userTier, setUserTier
}) => {

    const location = useLocation();
    const path = location.pathname.split("/")[2];

    const post = posts.find((p) => p.id.toString() === path);
    

   // console.log(location);
    
    return (
        <div>

            <div>
                <MainFunction_Tier_0
                    postId={post.id}
                    thereAreVideos={thereAreVideos}
                    setThereAreVideos={setThereAreVideos}
                    totalVideos={totalVideos}
                    setTotalVideos={setTotalVideos}
                    totalTimeSeconds={totalTimeSeconds}
                    setTotalTimeSeconds={setTotalTimeSeconds}
                    totalFileSizeMB={totalFileSizeMB}
                    setTotalFileSizeMB={setTotalFileSizeMB}
                />
            </div>

            {/*<div className="post">*/}
            {/*    <img src={post.img} alt="" className="postImg" />*/}
            {/*    <h1 className="postTitle">{post.title}</h1>*/}
            {/*    <p className="postDesc">{post.desc}</p>*/}
            {/*    <p className="postLongDesc">{post.longDesc}</p>*/}
            {/*</div>*/}
               
        </div>
    )

}

export default Post;