import React from 'react';


const ProgressBar = ({ progress }) => {
    return (
        <div className="progress-bar">

            <div className="progress-bar-inner" style={{ width: `${progress}%` }}>
                <div className="progress-label">{progress}%</div>
                <div className="progress-bar-fill"></div>
            </div>
        </div>
    );
};



export default ProgressBar;