// REAL TIME

import React, { useState, useEffect, useRef, useCallback } from "react";
import "./Style.css";
import "../App.css";



class Particle {
    constructor(canvas) {
        this.canvas = canvas;
        this.reset();
    }

    reset() {
        this.size = 1 + Math.random() * 2;
        this.x = Math.random() * this.canvas.width;
        this.y = this.canvas.height + this.size;
        this.speedX = (Math.random() - 0.5) * 2;
        this.speedY = -(Math.random() * 2 + 1);
        this.color = `hsla(${Math.random() * 30 + 20}, 80%, 50%, ${Math.random() * 0.55 + 0.05})`;
        this.lifetime = 0;
        this.maxLifetime = Math.random() * 120 + 180;
    }

    update() {
        this.x += this.speedX;
        this.y += this.speedY;
        this.lifetime++;
        if (this.lifetime > this.maxLifetime) {
            this.reset();
        }
    }

    draw(ctx) {
        ctx.fillStyle = this.color;
        ctx.globalAlpha = 1 - this.lifetime / this.maxLifetime;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
        ctx.globalAlpha = 1;
    }
}








function AudioVisualizer34e({
    selectedFont,
    imgSourceBackground,
    imgSourceCover,
    handleWindowButtonClick,
    color1,
    color2,
    color3,
    minHueValue,
    maxHueValue,
    selectedAudioFile,
    postId,
    audioFiles,
    albumTracksNames,
    longestName,
    concatenatedVideoTitle,
    tracksAlignment,
    albumCheck,
    hideBackgroundMask,
    hideCoverMask
}) {


    const particles = useRef([]);

    const [canvasDimensions, setCanvasDimensions] = useState({ width: 1280, height: 1280 * 9 / 16 });

    const images = useRef({
        backgroundImage: new Image(),
        coverImage: new Image()
    });



    const fileuploadRef = useRef(null);


    const [fileUpload, setFileUpload] = useState({});

    const [canvasScaledHeight, setCanvasScaledHeight] = useState({});
    const [canvasScaledWidth, setCanvasScaledWidth] = useState({});




    const updateCanvasDimensions = useCallback(() => {
        const scaleFactor = window.innerWidth * 0.35 / 1280;
        setCanvasDimensions({
            width: 1280 * scaleFactor,
            height: 1280 * 9 / 16 * scaleFactor
        });
    }, []);







    const originalCanvasWidth = 1280;


    const containerRef = useRef(null);
    const canvasRef = useRef(null);
    let audioRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    let audioSource;
    const sourceNodeRef = useRef(null);


    const backgroundImage = new Image();
    const coverImage = new Image();

    const selectedFontRef = useRef(selectedFont);
    const imgSourceBackgroundRef = useRef(imgSourceBackground);
    const imgSourceCoverRef = useRef(imgSourceCover);   
    const color1Ref = useRef(color1);
    const color2Ref = useRef(color2);
    const color3Ref = useRef(color3);
    const minHueValueRef = useRef(minHueValue);
    const maxHueValueRef = useRef(maxHueValue);
    const selectedAudioFileRef = useRef(selectedAudioFile);
    const albumTracksNamesRef = useRef(albumTracksNames);
    const longestNameRef = useRef(longestName);
    const concatenatedVideoTitleRef = useRef(concatenatedVideoTitle);
    const tracksAlignmentRef = useRef(tracksAlignment);
    const albumCheckRef = useRef(albumCheck);
    const hideBackgroundMaskRef = useRef(hideBackgroundMask);
    const hideCoverMaskRef = useRef(hideCoverMask);




    const postIdRef = useRef(postId);

    useEffect(() => {
        postIdRef.current = postId;
    }, [postId]);





    //useEffect(() => {
    //    images.current.backgroundImage.src = imgSourceBackground;
    //    images.current.coverImage.src = imgSourceCover;
    //}, [imgSourceBackground, imgSourceCover]);


    const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);
    const [isCoverLoaded, setIsCoverLoaded] = useState(false);
    const [isAudioLoaded, setIsAudioLoaded] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);



    // Update isLoaded only when all assets are ready
    useEffect(() => {
        if (isBackgroundLoaded && isCoverLoaded && isAudioLoaded) {
            setIsLoaded(true);
        }
    }, [isBackgroundLoaded, isCoverLoaded, isAudioLoaded]);

    // Load images and set their respective states
    useEffect(() => {
        const backgroundImage = images.current.backgroundImage;
        const coverImage = images.current.coverImage;

        backgroundImage.src = imgSourceBackground;
        coverImage.src = imgSourceCover;

        backgroundImage.onload = () => setIsBackgroundLoaded(true);
        coverImage.onload = () => setIsCoverLoaded(true);
    }, [imgSourceBackground, imgSourceCover]);

    // Set isAudioLoaded when the audio file is loaded
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.onloadeddata = () => setIsAudioLoaded(true);
            audioRef.current.src = selectedAudioFile.sorcer;
        }
    }, [selectedAudioFile]);


      //useEffect(() => {
    //    setIsLoaded(true);
    //    if (selectedAudioFile) {
    //        audioRef.current.src = selectedAudioFile.sorcer;
    //        //audioRef.current.play();
    //    }
    //}, [selectedAudioFile]);



    const initParticles = useCallback((numParticles, canvas) => {
        const ctx = canvas.getContext('2d');
        particles.current = Array.from({ length: numParticles }, () => new Particle(ctx.canvas));
    }, []);



    const cleanupResources = () => {
        // Stop the audio
        //if (audioRef.current) {
        //    audioRef.current.pause();
        //    audioRef.current.src = "";
        //}

        // Disconnect the source node if it exists
        if (sourceNodeRef.current) {
            sourceNodeRef.current.disconnect();
            sourceNodeRef.current = null;
        }

        // Close the audio context if it exists
        if (audioContextRef.current) {
            audioContextRef.current.close();
            audioContextRef.current = null;
        }

        // Clear the particles array
        particles.current = [];
    };



    //TEXT

    useEffect(() => {
        if (!isLoaded) return;

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContextRef.current = audioContext;
        const analyser = audioContext.createAnalyser();
        analyserRef.current = analyser;

        const audioSource = audioContext.createMediaElementSource(audioRef.current);
        sourceNodeRef.current = audioSource;
        audioSource.connect(analyser);
        analyser.connect(audioContext.destination);
        analyser.fftSize = 256;

        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        initParticles(100, canvas);

        const animate = () => {
            if (!canvasRef.current || !analyserRef.current) return;

            analyser.getByteFrequencyData(dataArray);

            let currentRotation = 0;
            let currentScale = 1; // Default scale is 1 (no scaling)


            ctx.clearRect(0, 0, canvas.width, canvas.height);

            drawBackground(ctx, images.current.backgroundImage, canvas.width, canvas.height, currentRotation, currentScale,
                color1Ref.current, color2Ref.current, hideBackgroundMaskRef.current);

            //particles.current.forEach(particle => {
            //    particle.update();
            //    particle.draw(ctx);
            //});

            drawCover(ctx, images.current.coverImage, canvas.width, canvas.height, currentRotation, currentScale,
                color1Ref.current, color2Ref.current, hideCoverMaskRef.current);




            const ratio = canvas.width / originalCanvasWidth;



            let artistText = selectedAudioFileRef.current.artist;
            let songText = selectedAudioFileRef.current.title;

            ctx.font = `16px ${selectedFontRef.current}`; // Adjust font size and family as needed
            const initialFontSize = 150*ratio; // Set initial font size as needed
            let initialFontSize2 = 80 * ratio;
            let initialFontSize3 = 100 * ratio;


            const maxWidthArtist = canvas.width * 4 / 5; // Set max width as needed
            const maxWidthSong = canvas.width * 3 / 5; // Set max width as needed
            const maxWidthTracklist = canvas.width * 3 / 5; // Set max width as needed




            const currentPostId = postIdRef.current; // Use the ref value here
            if (currentPostId === 1 || currentPostId === 2) {
                drawSingleLineTextCentered(ctx, artistText, maxWidthArtist, initialFontSize, canvas.width, canvas.height,
                    selectedFontRef.current, color1Ref.current, color2Ref.current, color3Ref.current,
                    albumTracksNamesRef.current, longestNameRef.current, selectedAudioFileRef.current,
                    tracksAlignmentRef.current, albumCheckRef.current
                );
            }
            else if (currentPostId === 3) {
                drawSingleLineTextCentered(ctx, artistText, maxWidthTracklist, initialFontSize3, canvas.width, canvas.height,
                    selectedFontRef.current, color1Ref.current, color2Ref.current, color3Ref.current,
                    albumTracksNamesRef.current, longestNameRef.current, selectedAudioFileRef.current,
                    tracksAlignmentRef.current, albumCheckRef.current
                );


                drawAlbumTitle(ctx, concatenatedVideoTitleRef.current, maxWidthTracklist, initialFontSize3, canvas.width, canvas.height, selectedFontRef.current,
                    color1Ref.current, color2Ref.current, color3Ref.current, albumTracksNamesRef.current, longestNameRef.current, selectedAudioFileRef.current,
                    tracksAlignmentRef.current, albumCheckRef.current
                );
            }


            // Assuming the first text box has been drawn and we know its height (rectHeight from the function)
            let verticalOffsetForSecondBox = 0; // This assumes you want to start the second box right below the midpoint of the canvas


            drawSingleLineTextCentered2(ctx, songText, maxWidthSong, initialFontSize2, canvas.width, canvas.height, selectedFontRef.current,
                color1Ref.current, color2Ref.current, verticalOffsetForSecondBox, tracksAlignmentRef.current
            );









            requestAnimationFrame(animate);
        };

        animate();

        return () => {
            cleanupResources();
        };
    }, [isLoaded, initParticles]);


    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = canvasDimensions.width;
        canvas.height = canvasDimensions.height;
    }, [canvasDimensions]);




    function adjustFontSizeForWidth(ctx, text, initialFontSize, maxWidth, selectedFont, textId,
        canvasWidth, canvasHeight, albumTracksNames, albumCheck,minFontSize = 10) {

        const ratio = canvasWidth / originalCanvasWidth;
        //ATTENTION minfontsize altered here!
        minFontSize = minFontSize * ratio;

        let fontSize = initialFontSize;
        ctx.font = `${fontSize}px ${selectedFont}`;

        // Measure text width with the current font size
        let textWidth = ctx.measureText(text).width;
        let textHeight = fontSize;



        let maxHeight;
        if (albumCheck === false) {
             maxHeight = Math.floor(canvasHeight * 1 / 2);
}
        else if (albumCheck === true) {
             maxHeight = Math.floor(canvasHeight * 3 / 5);
        }



        // Function to calculate total list height
        const calculateListHeight = (fontSize, albumTracksNames) => {
            return fontSize * albumTracksNames.length;
        };
        // Decrease the font size until the text fits within maxWidth or reaches minFontSize

        const currentPostId = postIdRef.current; // Use the ref value here
        if (currentPostId === 1) {
            while (textWidth > maxWidth && fontSize > minFontSize) {
                fontSize--;
                ctx.font = `${fontSize}px ${selectedFont}`;
                textWidth = ctx.measureText(text).width;
            }
        }
        if (currentPostId === 2) {
            maxWidth = Math.round(maxWidth *3/5);
            while (textWidth > maxWidth && fontSize > minFontSize) {
                fontSize--;
                ctx.font = `${fontSize}px ${selectedFont}`;
                textWidth = ctx.measureText(text).width;
            }
        }

        if (currentPostId === 3) {
            maxWidth = Math.round(maxWidth * 4 / 5);
            if (textId === 'drawAlbumTitle') {
                while (textWidth > maxWidth && fontSize > minFontSize) {
                    fontSize--;
                    ctx.font = `${fontSize}px ${selectedFont}`;
                    textWidth = ctx.measureText(text).width;
                    textHeight = fontSize;
                }
            }
            else {
                while ((textWidth > maxWidth || calculateListHeight(fontSize, albumTracksNames) > maxHeight) && fontSize > minFontSize) {
                    //console.log(albumTracksNames);
                    fontSize--;
                    ctx.font = `${fontSize}px ${selectedFont}`;
                    textWidth = ctx.measureText(text).width;
                    textHeight = fontSize;
                }
            }
           
        }


        return fontSize; // Return the adjusted font size
    }






    function drawAlbumTitle(ctx, text, maxWidth, initialFontSize, canvasWidth, canvasHeight, selectedFont,
        color1, color2, color3, albumTracksNames, longestName, selectedAudioFile, tracksAlignment, albumCheck, padding = 5) {
        // Adjust the font size to fit the text within maxWidth

        ctx.save(); // Save the current state

        const ratio = canvasWidth / originalCanvasWidth;


        const textId = 'drawAlbumTitle';
        const adjustedFontSize = adjustFontSizeForWidth(ctx, text, initialFontSize, maxWidth, selectedFont, textId,
            canvasWidth, canvasHeight, albumTracksNames, albumCheck);
        


        ctx.font = `${adjustedFontSize}px ${selectedFont}`;

        // Neon glow configuration
        ctx.shadowColor = color3.color;
        ctx.shadowBlur = color3.opacity*ratio; // Increase the blur radius for a more pronounced glow

        // Calculate text width and height with the adjusted font size
        const metrics = ctx.measureText(text);
        const textWidth = metrics.width;
        const textHeight = adjustedFontSize; // Approximation of text height



        // Calculate the rectangle dimensions
        const rectWidth = Math.min(maxWidth, textWidth); // Use the lesser of maxWidth or textWidth + padding
        const rectHeight = textHeight + padding * 2;



        let rectX;


        // Position the middle of the text box at the center of the canvas (x-axis)
        if (tracksAlignment === 'left') {
            rectX = canvasWidth / 2 - canvasWidth * 0.01;
        }
        else if(tracksAlignment === 'center') {
             rectX = (canvasWidth / 2 + canvasWidth / 4 - rectWidth / 2 - canvasWidth * 0.02);
        }
        else if (tracksAlignment === 'right') {
            rectX = (canvasWidth - rectWidth - canvasWidth * 0.03);
        }



        

        // Position the bottom of the text box just above the middle of the canvas (y-axis)
        const rectY = Math.round(canvasHeight * 0.15 + rectHeight/2);


        // Draw the text over the rectangle
        // Adjust text x position to start from the left edge of the rectangle with padding
       const textX = rectX;
       const textY = rectY - padding; // Fine-tune based on the font

        
        ctx.globalAlpha = color1.opacity; //opacity
        ctx.fillStyle = color1.color; // Text color


        // Enhance the glow effect by drawing the text multiple times
        if (albumCheck === true) {
            for (let i = 0; i < 3; i++) {
                ctx.fillText('', textX, textY);
            }
        }
        else if (albumCheck === false) {
            for (let i = 0; i < 3; i++) {
                ctx.fillText(text, textX, textY);
            }
        }
       

        // Reset shadow properties to avoid affecting subsequent canvas drawings
        ctx.shadowColor = 'transparent';
        ctx.shadowBlur = 0;

        ctx.restore(); // Restore the previous state
    }












    function drawSingleLineTextCentered(ctx, text, maxWidth, initialFontSize, canvasWidth, canvasHeight, selectedFont,
        color1, color2, color3, albumTracksNames, longestName, selectedAudioFile, tracksAlignment, albumCheck,
        padding = 0) {
        // Adjust the font size to fit the text within maxWidth
        ctx.save(); // Save the current state
        let adjustedFontSize;
        const textId = 'drawSingleLineTextCentered';
        const ratio = canvasWidth / originalCanvasWidth;


        const currentPostId = postIdRef.current; // Use the ref value here
        if (currentPostId === 1 || currentPostId === 2) {
            adjustedFontSize = adjustFontSizeForWidth(ctx, text, initialFontSize, maxWidth, selectedFont, textId,
                canvasWidth, canvasHeight, albumTracksNames, albumCheck);
        }
        else if (currentPostId === 3) {
            adjustedFontSize = adjustFontSizeForWidth(ctx, longestName, initialFontSize, maxWidth, selectedFont, textId,
                canvasWidth, canvasHeight, albumTracksNames, albumCheck);
        }


        ctx.font = `${adjustedFontSize}px ${selectedFont}`;
        ctx.fillStyle = color1.color; // Text color


        // Neon glow configuration
        ctx.shadowColor = color2.color;
        ctx.shadowBlur = color2.opacity*ratio; // Increase the blur radius for a more pronounced glow

        // Calculate text width and height with the adjusted font size
        const metrics = ctx.measureText(text);
        const textWidth = metrics.width;
        const textHeight = adjustedFontSize; // Approximation of text height

        
        let rectX, rectY, rectWidth;

        if (currentPostId === 1) {
            // Calculate the rectangle dimensions
             rectWidth = Math.min(maxWidth, textWidth ); // Use the lesser of maxWidth or textWidth + padding
            const rectHeight = textHeight + padding * 2;

            // Position the middle of the text box at the center of the canvas (x-axis)


            if (tracksAlignment === 'left') {
                rectX = canvasWidth * 0.03;
            }
            // Position the middle of the text box at the center of the canvas (x-axis)
            else if (tracksAlignment === 'center') {
                rectX = (canvasWidth - rectWidth) / 2;
            }
            else if (tracksAlignment === 'right') {
                rectX = (canvasWidth - rectWidth - canvasWidth * 0.03);

            }
            // Position the bottom of the text box just above the middle of the canvas (y-axis)
            rectY = canvasHeight - (0.14 * canvasHeight);
        }





        else if (currentPostId === 2) {
            // Calculate the rectangle dimensions
             rectWidth = Math.min(maxWidth, textWidth); // Use the lesser of maxWidth or textWidth + padding
            const rectHeight = textHeight + padding * 2;

            // Position the middle of the text box at the center of the canvas (x-axis)

            if (tracksAlignment === 'left') {
                rectX = canvasWidth / 2 - canvasWidth * 0.01;
            }
            // Position the middle of the text box at the center of the canvas (x-axis)
            else if (tracksAlignment === 'center') {
                rectX = (canvasWidth / 2 + canvasWidth / 4 - rectWidth / 2 - canvasWidth * 0.02);
            }
            else if (tracksAlignment === 'right') {
                rectX = (canvasWidth - rectWidth - canvasWidth * 0.03);
            }


            // Position the bottom of the text box just above the middle of the canvas (y-axis)
            rectY = Math.round(canvasHeight / 2);
        }




        else if (currentPostId === 3) {
            // Calculate the rectangle dimensions
             rectWidth = Math.min(maxWidth, textWidth); // Use the lesser of maxWidth or textWidth + padding
            const rectHeight = textHeight + padding * 2;



              if ( tracksAlignment === 'left') {
                  rectX = canvasWidth / 2 - canvasWidth * 0.01;
            }
            // Position the middle of the text box at the center of the canvas (x-axis)
           else if ( tracksAlignment === 'center') {
                rectX = (canvasWidth / 2 + canvasWidth / 4 - rectWidth / 2 - canvasWidth * 0.02);
            }
              else if (tracksAlignment === 'right') {
                  rectX = (canvasWidth - rectWidth  - canvasWidth * 0.03);

              }
           

            // Position the middle of the text box at the center of the canvas (x-axis)
            //rectX = canvasWidth / 2;
            // Position the bottom of the text box just above the middle of the canvas (y-axis)
            //rectY = Math.round(((canvasHeight - albumTracksNames.length * textHeight) / 2) + textHeight + 0.07 * canvasHeight);



            if (albumCheck === true) {
                rectY = Math.round(((canvasHeight - albumTracksNames.length * 1.2 * textHeight) / 2) + textHeight);
            }


            else if (albumCheck === false) {
                rectY = Math.round(((canvasHeight - albumTracksNames.length * 1.2 * textHeight) / 2) + textHeight + 0.07 * canvasHeight);
            }

            
        }


        // Draw the text over the rectangle
        // Adjust text x position to start from the left edge of the rectangle with padding
        let textX, textY;
        if (currentPostId === 1 || currentPostId === 2) {
             textX = rectX;
             textY = rectY - padding; // Fine-tune based on the font
        }

        else if (currentPostId === 3) {
            textX = rectX;
            textY = rectY - padding; // Fine-tune based on the font
        }


        ctx.globalAlpha = color1.opacity; //opacity


        // Enhance the glow effect by drawing the text multiple times
        for (let i = 0; i < 3; i++) {

            if (currentPostId === 1) {
                ctx.fillText(text, textX, textY);
            }
            else if (currentPostId === 2) {
                ctx.fillText(text, textX, textY);
            }

            else if (currentPostId === 3) {
                // Draw each name
                albumTracksNames.forEach((name, index) => {
                    if (albumTracksNames[index] === selectedAudioFile.name) {
                        ctx.shadowColor = color3.color;
                        ctx.shadowBlur = color3.opacity*ratio;

                    }
                    else {
                        ctx.shadowColor = color2.color;
                        ctx.shadowBlur = color2.opacity*ratio;
                    }
                    let metrics2 = ctx.measureText(albumTracksNames[index]);
                    let textWidth2 = metrics2.width;



                    if (tracksAlignment === 'center') {
                       // console.log('center');
                        ctx.fillText(name, textX + (rectWidth -textWidth2)/2, textY + index * textHeight * 1.2);
                    }
                    else if ( tracksAlignment === 'left') {
                        ctx.fillText(name, textX, textY + index * textHeight * 1.2);
                    }

                    else if (tracksAlignment === 'right') {
                        ctx.fillText(name, textX + rectWidth - textWidth2 , textY + index * textHeight * 1.2);
                    }


                });
            }
        }

        // Reset shadow properties to avoid affecting subsequent canvas drawings
        ctx.shadowColor = 'transparent';
        ctx.shadowBlur = 0;

        ctx.restore(); // Restore the previous state
    }








    function drawSingleLineTextCentered2(ctx, text, maxWidth, initialFontSize, canvasWidth, canvasHeight,
        selectedFont, color1, color2, verticalOffset, tracksAlignment,albumCheck,padding = 5) {
        const textId = 'drawSingleLineTextCentered2';

        ctx.save(); // Save the current state

        const ratio = canvasWidth / originalCanvasWidth;


        const adjustedFontSize = adjustFontSizeForWidth(ctx, text, initialFontSize, maxWidth, selectedFont, textId,
            canvasWidth, canvasHeight, albumTracksNames, albumCheck);
        ctx.font = `${adjustedFontSize}px ${selectedFont}`;
        ctx.fillStyle = color1.color; // Text color


        // Neon glow configuration
        ctx.shadowColor = color2.color; // Choose a glow color typical of neon lights
        ctx.shadowBlur = color2.opacity*ratio; // Increase the blur radius for a more pronounced glow


        // Calculate text width and height with the adjusted font size
        const metrics = ctx.measureText(text);
        const textWidth = metrics.width;
        const textHeight = adjustedFontSize; // Approximation of text height




        let rectX, rectY, rectWidth, rectHeight;

        const currentPostId = postIdRef.current; // Use the ref value here
        if (currentPostId === 1) {
            // Calculate the rectangle dimensions
             rectWidth = Math.min(maxWidth, textWidth ); // Use the lesser of maxWidth or textWidth + padding
             rectHeight = textHeight + padding * 2;




            if (tracksAlignment === 'left') {
                rectX = canvasWidth * 0.03;
            }
            // Position the middle of the text box at the center of the canvas (x-axis)
            else if (tracksAlignment === 'center') {
                rectX = (canvasWidth - rectWidth) / 2;
            }
            else if (tracksAlignment === 'right') {
                rectX = (canvasWidth - rectWidth - canvasWidth * 0.03);

            }


             rectY = (canvasHeight - (0.13 * canvasHeight));
        }
        else if (currentPostId === 2) {
            // Calculate the rectangle dimensions
             rectWidth = Math.min(maxWidth, textWidth); // Use the lesser of maxWidth or textWidth + padding
             rectHeight = textHeight + padding * 2;

            // Position the rectangle based on verticalOffset
            rectX = (canvasWidth / 2 + canvasWidth / 4 - rectWidth / 2 - canvasWidth * 0.02);
            if (tracksAlignment === 'left') {
                rectX = canvasWidth / 2 - canvasWidth * 0.01;
            }
            // Position the middle of the text box at the center of the canvas (x-axis)
            else if (tracksAlignment === 'center') {
                rectX = (canvasWidth / 2 + canvasWidth / 4 - rectWidth / 2 - canvasWidth * 0.02);
            }
            else if (tracksAlignment === 'right') {
                rectX = (canvasWidth - rectWidth - canvasWidth * 0.03);
            }
             rectY = Math.round(canvasHeight/2);
        }
        else if (currentPostId === 3) {
            // Calculate the rectangle dimensions
            rectWidth = Math.min(maxWidth, textWidth); // Use the lesser of maxWidth or textWidth + padding
            rectHeight = textHeight + padding * 2;

            // Position the rectangle based on verticalOffset
            rectX = (canvasWidth - rectWidth) / 2;
            //const rectY = (canvasHeight / 2) + verticalOffset; // Adjusted for vertical positioning
            //const rectY = (canvasHeight - (1.2 * rectHeight)) + verticalOffset;
            rectY = (canvasHeight - (0.13 * canvasHeight));
        }




        // Draw the text over the rectangle
        //const textX = rectX + (rectWidth / 2) - (textWidth / 2);
        const textX = rectX;
        const textY = rectY + rectHeight - 2*padding - (textHeight * 0.2); // Fine-tune based on the font

        ctx.globalAlpha = color1.opacity; //opacity
      //  ctx.fillStyle = 'white'; // Text color
       // ctx.fillText(text, textX, textY);

        // Enhance the glow effect by drawing the text multiple times

        for (let i = 0; i < 3; i++) {
            if (currentPostId === 1) {
                ctx.fillText(text, textX, textY);
            }
            if (currentPostId === 2) {
                ctx.fillText(text, textX, textY + (20 * ratio));
            }
            if (currentPostId === 3) {
                ctx.fillText('', textX, textY);

            }
        }

        // Reset shadow properties to avoid affecting subsequent canvas drawings
        ctx.shadowColor = 'transparent';
        ctx.shadowBlur = 0;
        ctx.restore(); // Restore the previous state

    }








    //PARTICLES
    // Example definitions - adjust these values based on your requirements
    const beatThreshold = 250; // Adjust based on the amplitude of your audio data











        useEffect(() => {
            selectedFontRef.current = selectedFont;
            imgSourceBackgroundRef.current = imgSourceBackground;
            imgSourceCoverRef.current = imgSourceCover;
            color1Ref.current = color1;
            color2Ref.current = color2;
            color3Ref.current = color3;
            minHueValueRef.current = minHueValue;
            maxHueValueRef.current = maxHueValue;
            selectedAudioFileRef.current = selectedAudioFile;
            albumTracksNamesRef.current = albumTracksNames;
            longestNameRef.current = longestName;
            concatenatedVideoTitleRef.current = concatenatedVideoTitle;
            tracksAlignmentRef.current = tracksAlignment;
            albumCheckRef.current = albumCheck;
            hideBackgroundMaskRef.current = hideBackgroundMask;
            hideCoverMaskRef.current = hideCoverMask;

     
            
        }, [selectedFont, imgSourceBackground, imgSourceCover, color1, color2, color3, minHueValue, maxHueValue, selectedAudioFile,
            albumTracksNames, longestNameRef, concatenatedVideoTitle, tracksAlignment, albumCheck, hideBackgroundMask,hideCoverMask]); // Dependencies array, effect runs when these values change








    function drawBackground(ctx, backgroundImage, canvasWidth, canvasHeight, currentRotation, currentScale, color1, color2, hideBackgroundMask) {
        // Clear the canvas or fill it with a background color if necessary
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);


        // Save the current context state
        const ratio = canvasWidth / originalCanvasWidth;
        let scale = 1;

        const scaledWidth = backgroundImage.width * scale * ratio;
        const scaledHeight = backgroundImage.height * scale * ratio;



        ctx.save();

        // Move the context to the center of the canvas
        ctx.translate(canvasWidth / 2, canvasHeight / 2);

        // Rotate the context
        //  ctx.rotate(rotationAngle);
        ctx.rotate(currentRotation);

        // const imageEnlarger = 10;
        //const imageEnlarger = Math.floor(0.014 * canvasHeight);  //0.014=10/720
        const imageEnlarger = 0;  //0.014=10/720
        // Move the context back and draw the image
        // The image is drawn centered at the transformed origin
        ctx.drawImage(backgroundImage, -(canvasWidth + imageEnlarger) / 2, -(canvasHeight + imageEnlarger) / 2,
            canvasWidth + imageEnlarger, canvasHeight + imageEnlarger);




        ctx.shadowColor = color2.color;
        ctx.shadowBlur = color2.opacity*ratio*2; // Increase the blur radius for a more pronounced glow

        if (hideBackgroundMask === false) {
            ctx.globalAlpha = color1.opacity; //opacity
        }
        else if (hideBackgroundMask === true) {
            ctx.globalAlpha = 0; //opacity
        }


        ctx.strokeStyle = color2.color; // Match stroke color with the glow for consistency
        ctx.lineWidth = 30*ratio; // Border thickness


        // Enhance the glow effect by drawing the border multiple times
        for (let i = 0; i < 3; i++) {
            // Step 3: Draw the rectangle around the image
            ctx.strokeRect(-(scaledWidth + ctx.lineWidth) / 2, -(scaledHeight + ctx.lineWidth) / 2,
                scaledWidth + ctx.lineWidth, scaledHeight + ctx.lineWidth);
        }


        // Reset shadow properties to avoid affecting subsequent drawings
        ctx.shadowColor = 'transparent';

        ctx.shadowBlur = 0;
        ctx.restore();




        // Restore the context to its original state
        ctx.restore();
    }





            //ATTENTION, need to rescale and fix the original image dimensions

    const drawCover = (ctx, coverImage, canvasWidth, canvasHeight, currentRotation, currentScale, color1, color2, hideCoverMask) => {


        const ratio = canvasWidth /originalCanvasWidth;


        let centerX, centerY, scale;


        const currentPostId = postIdRef.current; // Use the ref value here
        if (currentPostId === 1) {
            scale = 0.8;

            // Calculate the rectangle dimensions
            centerX = canvasWidth / 2;
            centerY = canvasHeight / 2 - (canvasHeight) * 0.12;
        }
        else if (currentPostId === 2) {
            scale = 0.9;
            centerX = canvasWidth * 0.25;
            centerY = canvasHeight / 2;
        }
        else if (currentPostId === 3) {
            scale = 0.9;
            centerX = canvasWidth * 0.25;
            centerY = canvasHeight / 2;
        }


        const scaledWidth = coverImage.width * scale * ratio;
        const scaledHeight = coverImage.height * scale * ratio;



        ctx.save();
        ctx.translate(centerX, centerY);

            ctx.scale(currentScale, currentScale); // Apply dynamic scaling




            // Neon glow configuration for the border
            ctx.shadowColor = color2.color;
        ctx.shadowBlur = color2.opacity * ratio * 2; // Increase the blur radius for a more pronounced glow
        if (hideCoverMask === false) {
            ctx.globalAlpha = color1.opacity; //opacity
        }
        else if (hideCoverMask === true) {
            ctx.globalAlpha = 0; //opacity
        }
        ctx.strokeStyle = color1.color; // Match stroke color with the glow for consistency
            ctx.lineWidth = Math.floor(20 * ratio); // Border thickness
            //ctx.lineWidth = 30; // Border thickness


            // Enhance the glow effect by drawing the border multiple times
            for (let i = 0; i < 3; i++) {
                // Step 3: Draw the rectangle around the image
                //ctx.strokeRect(-scaledWidth / 2, -scaledHeight / 2, scaledWidth, scaledHeight);
                ctx.strokeRect(-(scaledWidth - ctx.lineWidth-2) / 2, -(scaledHeight - ctx.lineWidth-2) / 2,
                    scaledWidth - ctx.lineWidth-2, scaledHeight - ctx.lineWidth-2);
            }


            // Reset shadow properties to avoid affecting subsequent drawings
            ctx.shadowColor = 'transparent';

            ctx.shadowBlur = 0;

            ctx.save();

            ctx.restore();

            ctx.globalAlpha = 1; //opacity



        ctx.drawImage(coverImage, -scaledWidth / 2, -scaledHeight / 2, scaledWidth, scaledHeight);


        ctx.restore();
    };



   //with GLOW effect
    function drawVisualizer(ctx, canvas, bufferLength, barWidth, barHeight, dataArray, width, height, currentRotation, minHueValue, maxHueValue) {
        analyserRef.current.getByteTimeDomainData(dataArray); // Assuming this fills dataArray with frequency data

        let sliceWidth = width / dataArray.length;
        let x = 0;
        let verticalOffset = -100; // Try changing this to see effect

        let yCenter = height / 2 + verticalOffset;
        ctx.save();
        // Configure glow effect
        ctx.shadowBlur = 40; // Adjust the glow radius as needed
        ctx.shadowColor = 'rgba(0, 255, 255, 0.8)'; // cyan glow, adjust color and opacity as desired
       // ctx.rotate(currentRotation);


        for (let i = 0; i < dataArray.length; i++) {
            let v = yCenter - (dataArray[i] - 127) * 0.5; // Adjust multiplier for visibility

            //console.log(minHueValue);
            let hue = minHueValue + (i / dataArray.length) * (maxHueValue - minHueValue); // Ranges from 60 (yellow-greenish) to 120 (green)
           // let hue = 120 + (i / dataArray.length) * (240 - 120); // Ranges from 60 (yellow-greenish) to 120 (green)
            //let hue = (i / dataArray.length) * 20; // 0 (red) to 60 (yellow)
            let amplitude = dataArray[i];
            let lightness = 50 + (amplitude - 128) * 0.5; // Adjust lightness based on the amplitude
            lightness = Math.max(40, Math.min(lightness, 60)); // Clamp the lightness to a reasonable range


            ctx.strokeStyle = `hsl(${hue}, 80%, ${lightness}%)`; // Fire colors, Hue - saturation - lightness
            //Hue Ranges 0 to 360. 0 (or 360) is red, 120 is green, and 240 is blue. 

            ctx.lineWidth = 3; // Ensure lines are visible

            ctx.beginPath();
            ctx.moveTo(x, yCenter);
            ctx.lineTo(x, v);
            ctx.stroke();
            x += sliceWidth;
        }

        // Reset shadow properties to avoid affecting subsequent canvas drawings
        ctx.shadowBlur = 0;
        ctx.shadowColor = 'transparent';
        ctx.restore();
    }


    useEffect(() => {
        window.addEventListener('resize', updateCanvasDimensions);
        updateCanvasDimensions();
        return () => window.removeEventListener('resize', updateCanvasDimensions);
    }, [updateCanvasDimensions]);












    return (
        <div style={{ position: 'relative' }}>
            <div style={{ height: canvasDimensions.height, display: isLoaded ? 'block' : 'none' }}>
                <canvas ref={canvasRef} style={{ position: 'absolute', left: 0 }}></canvas>
                {/* Overlay divs and hover effects... */}
            </div>

            {/*<br/>*/}
            <audio ref={audioRef} controls controlsList="nodownload noplaybackrate"
                style={{ width: '100%', display: isLoaded ? 'block' : 'none' }}></audio>



            <div style={{ margin: 0, padding: 10 }}>
                <span>Selected Track:</span>
                <span className="simpleText"> {selectedAudioFile?.artist} - {selectedAudioFile?.title}</span>
            </div>
        </div>
    );
}



export default AudioVisualizer34e;



