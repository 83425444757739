import React from 'react';



const KofiButton = () => {
    const inviteLink = 'https://ko-fi.com/albumanimator'; // Replace with your actual KoFi invite link

    return (




         <button
            onClick={() => window.open(inviteLink , '_blank')}
            style={{
                backgroundColor: '#29abe0',
                color: '#FFF',
                border: 'none',
                padding: '5px 10px',
                fontSize: '16px',
                borderRadius: '5px',
                cursor: 'pointer'
            }}
        >
             Buy Me a Coffee!
        </button>

    );
};

export default KofiButton;
