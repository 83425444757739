import React from 'react';
import '../css/buttons.css';
import '../App.css';

const EditTemplatesButton = ({ onClick, label, isActive }) => {
    return (
        <button
            className={`svg-button ${isActive ? 'svg-button-active' : ''}`}
            onClick={onClick}
        >
            <svg
                className="svg-InColumn"
                style={{ fill: 'none' }}
                xmlns="http://www.w3.org/2000/svg"
                
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                viewBox="0 0 24 24"
            >
                <path d="M4 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6z"></path>
                <path d="M8 12h8v4H8v-4zM8 8h8"></path>
            </svg>
            {/* {label} */}
        </button>
    );
};

export default EditTemplatesButton;
